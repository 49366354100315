<div class="surface-overlay notifications-container overflow-auto px-2">
  <div class="mx-auto max-w-[920px]">
    <section class="my-4">
      <div class="flex flex-col items-center justify-center p-4">
        <div class="mb-3 mt-4">
          <div class="flex flex-col items-center gap-3 p-3">
            <img
              [src]="
                !isDark
                  ? '/assets/icons/logo-dark-512w.svg'
                  : '/assets/icons/logo-light-512w.svg'
              "
              alt="logo"
              class="h-12"
            />
            <img
              [src]="
                isDark
                  ? sdtkWebUrl + '/assets/icons/logo-light.svg'
                  : sdtkWebUrl + '/assets/icons/logo-dark.svg'
              "
              alt="logo"
              class="h-10"
            />
          </div>
        </div>
        <h2 class="h-level-2 mb-3 text-center">
          Mission Peak GPS Cycling and Running App
        </h2>
        <p class="h-level-4 mb-3 text-center">
          Strava lets you track your running and riding with GPS, join
          Challenges, share photos from your activities, and follow friends.
        </p>
        <div class="mb-3 p-2">
          <a class="mx-2">
            <img
              [src]="sdtkWebUrl + '/assets/icons/pwa.png'"
              alt="pwa"
              class="inline h-16"
            />
          </a>
        </div>
        <img
          [src]="sdtkWebUrl + '/assets/icons/mobile-feature.svg'"
          alt=""
          width="400"
        />
      </div>
    </section>
  </div>
</div>
