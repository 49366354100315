import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/main/store/model/state.model';
import { DEFAULT_HOME } from 'src/app/main/utilities';
import { PublicService } from '../public.service';

enum ObjectType {
  C = 'CARPOOL',
  H = 'HUDDLE',
  I = 'INSPIRATION',
  CM = 'COMMUNITY',
  P = 'POST',
}

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
})
export class JoinComponent {
  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private publicService: PublicService,
    private router: Router,
    private store: Store<AppState>,
  ) {
    const uid = this.route.snapshot.paramMap.get('uid');
    this.publicService.getPublicLinks(uid as string).subscribe({
      next: (data: any) => {
        const { objectId, objectType } = data;
        switch (objectType) {
          case ObjectType.C:
            this.router.navigate(['carpool', 'view', 'new', objectId]);
            break;
          case ObjectType.H:
            this.router.navigate(['join', 'huddle', objectId], {
              queryParams: { userId: data.forUser.id },
            });
            break;
          case ObjectType.I:
            this.router.navigate(['inspiration', 'view', objectId]);
            break;
          case ObjectType.CM:
            this.router.navigate(['community', 'view', objectId]);
            break;
          case ObjectType.P:
            this.router.navigate([DEFAULT_HOME, 'timeline', 'view', objectId]);
            break;
        }
      },
    });
  }
}
