<div
  class="mx-auto max-w-full pb-8 lg:container"
  role="main"
  *ngIf="!isLoading && community"
>
  <div class="surface-ground mb-12 flex flex-col">
    <p-card styleClass="rounded-none huddle-view padding-clear">
      <div class="relative m-auto flex w-full items-center items-stretch">
        <div class="bg-blur absolute h-[200px] w-full overflow-hidden">
          <img
            [src]="coverPhotoUrl | image | async"
            alt="community.name"
            *ngIf="community.coverImageId"
            [ngStyle]="{
              'top.px': community.coverImageCoordinate
                ? community.coverImageCoordinate.ycoordinate
                : 0,
            }"
            class="static top-0 min-h-full min-w-full shrink-0 object-cover object-top sm:absolute"
          />
          <img
            *ngIf="!community.coverImageId"
            src="../assets/images/community-default-cover.svg"
            alt=""
            class="static bottom-0 min-h-full min-w-full shrink-0 object-cover object-top sm:absolute"
          />
        </div>
        <div
          class="relative mx-auto flex w-full max-w-[860px] items-center items-stretch"
        >
          <div
            class="relative flex h-[200px] w-full items-center justify-center overflow-hidden"
          >
            <img
              [src]="coverPhotoUrl | image | async"
              alt="community.name"
              *ngIf="community.coverImageId"
              [ngStyle]="{
                'top.px': community.coverImageCoordinate
                  ? community.coverImageCoordinate.ycoordinate
                  : 0,
              }"
              class="static top-0 min-h-full min-w-full shrink-0 object-cover object-top sm:absolute"
            />
            <img
              *ngIf="!community.coverImageId"
              src="../assets/images/community-default-cover.svg"
              alt=""
              class="absolute bottom-0 min-h-full min-w-full shrink-0 object-cover object-top"
            />
          </div>
          <div>
            <div
              class="community-img absolute bottom-0 left-[44%] w-[200px] -translate-x-1/2 md:left-[10%]"
              *ngIf="!community.logoImageId"
            >
              <div
                class="surface-overlay absolute -top-[72px] left-[50px] rounded-md p-1 md:-top-[80px]"
              >
                <img
                  [src]="'./assets/images/community-default-logo.svg'"
                  alt="Image"
                  width="200"
                  height="150"
                />
              </div>
            </div>
            <div
              class="community-img absolute bottom-0 left-[44%] w-[200px] -translate-x-1/2 md:left-[10%]"
              *ngIf="community.logoImageId"
            >
              <div
                class="surface-overlay absolute -top-[120px] left-[50px] rounded-md p-1 md:-top-[80px]"
              >
                <img
                  [src]="logoUrl | image | async"
                  alt="Image"
                  width="200"
                  height="150"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="relative m-auto flex w-full max-w-[860px] items-center items-stretch pb-[16px]"
      >
        <div class="shrink-1 grow-1 flex w-full min-w-0 flex-col">
          <div class="w-full">
            <div
              class="space-between ml-0 mt-16 flex items-end justify-center px-2 pt-5 md:ml-[12rem] md:mt-0 md:justify-start md:pt-2"
            >
              <div
                class="shrink-1 grow-1 flex max-w-full flex-col items-center gap-2 pb-4 md:items-start"
              >
                <span class="highlight-text uppercase">{{
                  community.location
                    ? community.location.formattedAddress
                    : "No Location Selected"
                }}</span>
                <h2 class="h-level-2 font-semibold">
                  {{ community.name }}
                </h2>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-start gap-3 px-2 md:justify-end">
            <div
              class="flex"
              *ngIf="
                !community.membership.member &&
                !community.membership.joinRequested
              "
            >
              <button
                pButton
                type="button"
                (click)="selfJoinAndCancelRequest()"
                class="mp-button mp-button-outlined group h-12 w-full justify-center gap-2 rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
              >
                <span>Join Community</span>
              </button>
            </div>
            <div
              *ngIf="!isAcceptedOrDecline && community.membership.joinInvited"
              class="flex"
            >
              <button
                pButton
                type="button"
                (click)="accept($event)"
                class="mp-button mp-button-outlined group w-full justify-center gap-2 rounded-br-none rounded-tr-none px-5 py-2 leading-6 transition-all md:w-auto"
              >
                Accept Invite
              </button>
              <button
                pButton
                type="button"
                (click)="onMenuToggle($event, 'community-view-invite')"
                class="mp-button mp-button-outlined w-4/12 justify-center rounded-bl-none rounded-tl-none border-l-0 px-2 py-2 leading-6 transition-all md:w-auto"
              >
                <span class="material-icons"> expand_more </span>
              </button>
            </div>
            <div
              *ngIf="community.membership.member && community.invitationAllowed"
              class="flex"
            >
              <button
                pButton
                type="button"
                (click)="onInviteClick()"
                class="mp-button mp-button-outlined group h-12 w-full justify-center gap-2 rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
              >
                <span>Invite</span>
              </button>
            </div>
            <div class="flex">
              <button
                pButton
                type="button"
                (click)="onInviteClick(!0)"
                class="mp-button mp-button-outlined group h-12 w-full justify-center gap-2 rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
              >
                <span>Share</span>
              </button>
            </div>
            <div class="flex">
              <button
                pButton
                type="button"
                (click)="onMenuToggle($event, 'community-main')"
                class="mp-button mp-button-outlined h-12 w-full items-baseline justify-center rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
              >
                ...
              </button>
            </div>
          </div>
        </div>
      </div>
    </p-card>
    <div class="relative">
      <div class="flex max-w-full flex-col items-center">
        <div class="w-full">
          <div class="mx-auto max-w-[1120px] p-2">
            <div class="mb-2 grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-8">
              <div class="col-span-8 md:col-span-5">
                <div class="flex flex-col gap-2">
                  <p-card class="flex-1" styleClass="h-full trends rounded-lg">
                    <div class="px-2">
                      <h4 class="h-level-2 -mx-6 mx-2 py-3">About</h4>
                    </div>
                    <div class="flex flex-col px-4">
                      <div class="h-level-3 mb-2">
                        <div class="break-words" *ngIf="community.description">
                          <app-description-content
                            [contentHTML]="community.description"
                          ></app-description-content>
                        </div>
                      </div>
                      <div class="mb-2 flex gap-1">
                        <strong>Location: </strong>
                        <span>{{ community.location.name }}</span>
                      </div>
                      <div class="mb-2 flex gap-1">
                        <strong>Founded on: </strong>
                        <span>{{
                          community.incorporatedAt | date: "fullDate"
                        }}</span>
                      </div>
                      <div class="mb-2 flex gap-1">
                        <strong>Privacy: </strong>
                        <span>{{ visibilityMap[community.privacy] }}</span>
                      </div>
                      <div class="mb-2 flex items-center gap-1">
                        <strong>Topics: </strong>
                        <p-chip
                          styleClass="m-0 no-hover"
                          *ngFor="let tag of community.tags"
                          [label]="tag.name"
                        />
                      </div>
                    </div>
                  </p-card>
                </div>
              </div>
              <div class="col-span-8 flex-1 md:col-span-3">
                <div class="flex flex-col gap-2">
                  <p-card class="flex-1" styleClass="h-full trends">
                    <div class="px-2">
                      <h4 class="h-level-2 -mx-6 mx-2 py-3">External Links</h4>
                    </div>
                    <div class="px-3">
                      <div class="pb-3 pt-1">
                        <div class="mb-2 flex gap-1 truncate">
                          <strong>Website: </strong>
                          <a
                            [href]="community.externalWebUrl"
                            class="primary truncate"
                            ><span>{{ community.externalWebUrl }}</span></a
                          >
                        </div>
                        <div class="mb-2 flex gap-1">
                          <strong>Video:</strong>
                          <a
                            [href]="community.externalVideoUrl"
                            class="primary truncate"
                            ><span>{{ community.externalVideoUrl }}</span></a
                          >
                        </div>
                        <div
                          class="mb-2 flex gap-1"
                          *ngIf="community.externalSocialUrl1 !== ''"
                        >
                          <strong>External Social URL 1:</strong>
                          <a
                            [href]="community.externalSocialUrl1"
                            class="primary truncate"
                            ><span>{{ community.externalSocialUrl1 }}</span></a
                          >
                        </div>
                        <div
                          class="flex gap-1"
                          *ngIf="community.externalSocialUrl2 !== ''"
                        >
                          <strong>External Social URL 2:</strong>
                          <a
                            [href]="community.externalSocialUrl2"
                            class="primary truncate"
                            ><span>{{ community.externalSocialUrl2 }}</span></a
                          >
                        </div>
                        <div
                          class="flex gap-1"
                          *ngIf="community.externalSocialUrl3 !== ''"
                        >
                          <strong>External Social URL 3:</strong>
                          <a
                            [href]="community.externalSocialUrl3"
                            class="primary truncate"
                          ></a
                          ><span>{{ community.externalSocialUrl3 }}</span>
                        </div>
                        <div
                          class="flex gap-1"
                          *ngIf="community.externalSocialUrl4 !== ''"
                        >
                          <strong>External Social URL 4:</strong>
                          <a
                            [href]="community.externalSocialUrl4"
                            class="primary truncate"
                            ><span>{{ community.externalSocialUrl4 }}</span></a
                          >
                        </div>
                        <div
                          class="flex gap-1"
                          *ngIf="community.externalSocialUrl5 !== ''"
                        >
                          <strong>External Social URL 5:</strong>
                          <a
                            [href]="community.externalSocialUrl5"
                            class="primary truncate"
                            ><span>{{ community.externalSocialUrl5 }}</span></a
                          >
                        </div>
                      </div>
                    </div>
                  </p-card>
                  <p-card class="flex-1" styleClass="h-full padding-clear">
                    <div class="px-2">
                      <h4 class="h-level-2 -mx-6 mx-2 py-3">Huddle Summary</h4>
                    </div>
                    <div class="mx-3 flex justify-between gap-3 p-2">
                      <div
                        class="menu-item flex flex-1 cursor-pointer flex-col items-center justify-center gap-1 rounded-md py-3 transition duration-300"
                      >
                        <span class="jumbo text-emerald-400">{{
                          community.statistics.totalConfirmedHuddles
                        }}</span>
                        <span class="h-level-4 pb-2 text-center"
                          >Going Huddles</span
                        >
                      </div>
                      <div
                        class="menu-item flex flex-1 cursor-pointer flex-col items-center justify-center gap-1 rounded-md py-3 transition duration-300"
                      >
                        <span class="jumbo text-emerald-400">{{
                          community.statistics.totalUpcomingHuddles
                        }}</span>
                        <span class="h-level-4 pb-2 text-center"
                          >Upcoming Huddles</span
                        >
                      </div>
                      <div
                        class="menu-item flex flex-1 cursor-pointer flex-col items-center justify-center gap-1 rounded-md py-3 transition duration-300"
                      >
                        <span class="jumbo text-emerald-400">{{
                          community.statistics.totalPastHuddles
                        }}</span>
                        <span class="h-level-4 pb-2 text-center"
                          >Past Huddles</span
                        >
                      </div>
                    </div>
                  </p-card>
                  <p-card class="flex-1" styleClass="h-full padding-clear">
                    <div class="px-2">
                      <h4 class="h-level-2 -mx-6 mx-2 py-3">Members Summary</h4>
                    </div>
                    <div class="mx-3 flex justify-between gap-3 p-2">
                      <div
                        class="menu-item flex flex-1 cursor-pointer flex-col items-center justify-center gap-1 rounded-md py-3 transition duration-300"
                      >
                        <span class="jumbo text-emerald-400">{{
                          community.statistics.totalMembers
                        }}</span>
                        <span class="h-level-4 pb-2 text-center">Members</span>
                      </div>
                      <div
                        class="menu-item flex flex-1 cursor-pointer flex-col items-center justify-center gap-1 rounded-md py-3 transition duration-300"
                      >
                        <span class="jumbo text-emerald-400">{{
                          community.statistics.totalMembersFollowing
                        }}</span>
                        <span class="h-level-4 pb-2 text-center"
                          >Followings</span
                        >
                      </div>
                      <div
                        class="menu-item flex flex-1 cursor-pointer flex-col items-center justify-center gap-1 rounded-md py-3 transition duration-300"
                      >
                        <span class="jumbo text-emerald-400">{{
                          community.statistics.totalJoinRequests
                        }}</span>
                        <span class="h-level-4 pb-2 text-center"
                          >Requested</span
                        >
                      </div>
                    </div>
                  </p-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>

<p-tieredMenu
  #menu
  [model]="communityMoreOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
>
  <ng-template pTemplate="item" let-item>
    <a
      *ngIf="itemFor === item.item"
      (click)="onTieredMenuItemClick(item.action)"
      class="p-menuitem-link justify-content-between align-items-center flex p-3"
    >
      <div>
        <span class="ml-2"> {{ item.label }}</span>
      </div>
    </a>
  </ng-template>
</p-tieredMenu>
