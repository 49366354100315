<main class="main">
  <section class="surface-overlay">
    <div
      class="mx-auto flex max-w-[1120px] flex-col items-start justify-start pt-0 md:py-3"
    >
      <div class="px-2 py-4">
        <h4 class="h-level-4 primary">Inspirations</h4>
        <h1 class="h-level-1 mb-3">Stories and Articles</h1>
        <p class="mb-8">
          Read the stories from the world. Get inspired and inspire others.
        </p>
      </div>
      <app-shared-inspiration class="w-full"></app-shared-inspiration>
    </div>
  </section>
  <section class="mt-16 px-4 xl:px-0">
    <div class="mx-auto max-w-[1120px]">
      <h2 class="h-level-2 primary mb-4 text-center font-semibold">
        Share your stories and inspire the world!
      </h2>
      <p class="pb-6 text-center">
        Do you have an awesome story to tell? Pen down your awesome jounery and
        inspire others to live active and healthy life.
      </p>
      <div class="mb-6 flex justify-center gap-2">
        <div>
          <button
            type="button"
            pButton
            class="mp-button mp-button-outlined mb-0 flex truncate rounded-md px-5 py-2 transition-all lg:w-auto"
          >
            <span>Learn More</span>
          </button>
        </div>
        <div>
          <button
            type="button"
            pButton
            (click)="getStarted()"
            class="mp-button mp-button-filled mb-0 flex truncate rounded-md px-5 py-2 transition-all lg:w-auto"
          >
            <span>Get Started</span>
          </button>
        </div>
      </div>
      <div class="flex">
        <div
          class="relative flex h-[130px] w-full items-center justify-center overflow-hidden rounded-tl-lg rounded-tr-lg border-l-8 border-r-8 border-t-8 border-black md:h-[540px]"
        >
          <img
            [src]="
              isDark
                ? '../assets/images/inspiration-editor-dark.png'
                : '../assets/images/inspiration-editor-light.png'
            "
            alt=""
            class="absolute top-0 min-h-full min-w-full shrink-0 rounded-md object-cover object-top"
          />
        </div>
      </div>
    </div>
  </section>
  <section class="surface-overlay px-8 py-12">
    <div
      class="mx-auto flex max-w-[1120px] flex-col items-center justify-center p-2 text-center"
    >
      <h1 class="h-level-1 highlight-text mb-3">Join our newsletter</h1>
      <p class="mb-8 text-center">
        Be the first one to know what we are cooking :-)
      </p>
      <div class="flex gap-3" *ngIf="!subscribed">
        <div>
          <input
            placeholder="Enter your email"
            pInputText
            type="text"
            [(ngModel)]="subscriberEmail"
            (change)="validateEmail($event)"
            class="block h-12 w-full rounded-md border px-2 py-1.5"
            [ngClass]="{ 'border-red-200': !isValidEmail }"
          />
          <span *ngIf="!isValidEmail" class="block text-start text-red-500"
            >Email is invalid</span
          >
        </div>
        <div>
          <p-button
            label="Subscribe"
            (click)="onSubscribeNewLetter()"
            [loading]="isLoading"
            [disabled]="subscriberEmail === ''"
            styleClass="mp-button mp-button-filled py-2 px-4 h-12 rounded ring-0 outline-0"
          ></p-button>
        </div>
      </div>
      <div *ngIf="subscribed">Subscribed to newletter</div>
    </div>
  </section>
  <app-footer></app-footer>
</main>
