<div
  class="huddle-container container mx-auto max-w-full pb-8"
  role="main"
  *ngIf="!isLoading"
>
  <div class="surface-ground mb-12 flex flex-col">
    <p-card
      styleClass="rounded-none huddle-view padding-clear"
      *ngIf="huddleView"
    >
      <div class="relative m-auto flex w-full items-center items-stretch">
        <div class="bg-blur absolute h-[200px] w-full overflow-hidden">
          <img
            [src]="coverPhotoUrl | image | async"
            alt="huddleView.name"
            *ngIf="huddleView.coverPhotoUrl"
            [ngStyle]="{
              'top.px': 0,
            }"
            class="static top-0 min-h-full min-w-full shrink-0 object-cover object-top sm:absolute"
          />
          <img
            *ngIf="!huddleView.coverPhotoUrl"
            [src]="
              activityEnum.Hiking !== huddleView.activity
                ? '../assets/images/huddle-default-cover.svg'
                : '../assets/images/huddle-default-cover-hiking.svg'
            "
            alt=""
            class="static -bottom-[60px] min-h-full min-w-full shrink-0 object-cover object-top sm:absolute"
          />
        </div>
        <div
          class="relative mx-auto flex w-full max-w-[860px] items-center items-stretch"
        >
          <div
            class="relative flex h-[200px] w-full items-center justify-center overflow-hidden"
          >
            <img
              [src]="coverPhotoUrl | image | async"
              alt="huddleView.name"
              *ngIf="huddleView.coverPhotoUrl"
              [ngStyle]="{
                'top.px': huddleView.coverPhotoCoordinate?.ycoordinate,
              }"
              class="static top-0 min-h-full min-w-full shrink-0 object-cover object-top sm:absolute"
            />
            <img
              *ngIf="!huddleView.coverPhotoUrl"
              [src]="
                activityEnum.Hiking !== huddleView.activity
                  ? '../assets/images/huddle-default-cover.svg'
                  : '../assets/images/huddle-default-cover-hiking.svg'
              "
              alt=""
              class="static -bottom-[60px] min-h-full min-w-full shrink-0 object-cover object-top sm:absolute"
            />
          </div>
        </div>
      </div>
      <div
        class="relative m-auto flex w-full max-w-[860px] items-center items-stretch pb-[16px]"
      >
        <div class="shrink-1 grow-1 flex w-full min-w-0 flex-col">
          <div class="mt-6 flex h-0 items-end" *ngIf="huddleView.startDateTime">
            <div
              class="relative ml-4 flex shrink items-stretch justify-between pr-[12px] pt-[16px]"
            >
              <div
                class="shrink-1 relative flex min-w-0 max-w-full flex-col pb-[6px] pt-[6px]"
              >
                <div
                  class="flex h-[80px] w-[80px] flex-col items-stretch overflow-hidden rounded-bl-md rounded-br-md"
                  style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px"
                >
                  <div
                    class="h-[28px] rounded-tl-md rounded-tr-md bg-rose-500 py-1 text-center text-white"
                  >
                    {{ eventLongDate | date: "MMM" }}
                  </div>
                  <div
                    class="flex h-[60px] items-center justify-center bg-white text-4xl font-bold text-black"
                  >
                    {{ eventDate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="space-between flex items-end px-2 pt-[16px]">
              <div class="shrink-1 grow-1 flex max-w-full flex-col pb-[8px]">
                <span
                  *ngIf="huddleView.startDateTime"
                  class="highlight-text uppercase"
                  >{{ eventLongDate | date: "EEEE, MMMM d, y, h:mm a" }}
                  {{ eventLongDateEnd ? "-" : "" }}
                  {{ eventLongDateEnd | date: "EEEE, MMMM d, y, h:mm a" }}</span
                >
                <span class="highlight-text" *ngIf="!huddleView.startDateTime"
                  >No date selected</span
                >
                <h2 class="h-level-2 font-semibold">
                  {{ huddleView.name }}
                </h2>
                <h3 class="h-level-3 my-1">
                  {{
                    huddleView.venue
                      ? huddleView.venue.name
                      : "No location selected"
                  }}
                </h3>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-end gap-3 px-2">
            <div class="" *ngIf="!['YES', 'MAYBE'].includes(rsvpType)">
              <button
                pButton
                (click)="interestAndUnInterest()"
                type="button"
                [ngClass]="
                  huddleView.participation.interested ? '' : 'hover:bg-teal-500'
                "
                class="mp-button mp-button-outlined group h-12 w-full justify-center gap-2 rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
              >
                <span
                  [ngClass]="
                    !huddleView.participation.interested
                      ? 'hidden group-hover:block'
                      : 'block'
                  "
                  class="material-icons"
                >
                  star
                </span>
                <span
                  class="material-icons"
                  [ngClass]="
                    !huddleView.participation.interested
                      ? 'block group-hover:hidden'
                      : 'hidden'
                  "
                >
                  star_outline
                </span>
                <span class="hidden sm:block">Interested</span>
              </button>
            </div>
            <div
              class="flex"
              *ngIf="
                huddleView.participation.requested ||
                huddleView.participation.waiting
              "
            >
              <button
                pButton
                type="button"
                [ngClass]="'hover:bg-teal-500'"
                class="mp-button mp-button-outlined group h-12 w-full justify-center gap-2 rounded-md rounded-br-none rounded-tr-none px-5 py-2 leading-6 transition-all md:w-auto"
              >
                <span class="material-icons"> hourglass_top </span>
                <span class="sm:hidden xl:block">{{
                  huddleView.participation.requested ? "Requested" : "Waiting"
                }}</span>
              </button>
              <button
                pButton
                type="button"
                (click)="requested.toggle($event)"
                class="mp-button mp-button-outlined h-12 w-4/12 justify-center rounded-bl-none rounded-tl-none border-l-0 px-2 py-2 leading-6 transition-all md:w-auto"
              >
                <span class="material-icons"> expand_more </span>
              </button>
            </div>
            <div
              class="flex"
              *ngIf="
                huddleView.participation.rsvped ||
                !(
                  huddleView.participation.requested ||
                  huddleView.participation.waiting
                )
              "
            >
              <button
                pButton
                (click)="rsvp('YES')"
                type="button"
                class="mp-button mp-button-outlined group h-12 w-full justify-center gap-2 rounded-br-none rounded-tr-none px-5 py-2 leading-6 transition-all md:w-auto"
              >
                <span
                  [ngClass]="
                    rsvpType !== 'YES'
                      ? 'hidden group-hover:block'
                      : 'enabled block'
                  "
                  class="material-icons"
                >
                  check_circle
                </span>
                <span
                  class="material-icons"
                  [ngClass]="
                    rsvpType !== 'YES' ? 'block group-hover:hidden' : 'hidden'
                  "
                >
                  check_circle_outline
                </span>
                <span class="prevent">{{
                  rsvpType === "YES" ? "Confirmed" : "Confirm"
                }}</span>
              </button>
              <button
                pButton
                type="button"
                (click)="going.toggle($event)"
                class="mp-button mp-button-outlined h-12 w-4/12 justify-center rounded-bl-none rounded-tl-none border-l-0 px-2 py-2 leading-6 transition-all md:w-auto"
              >
                <span class="material-icons"> expand_more </span>
              </button>
            </div>
            <div class="flex">
              <button
                pButton
                type="button"
                (click)="menu.toggle($event)"
                class="mp-button mp-button-outlined h-12 w-full items-baseline justify-center rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
              >
                ...
              </button>
            </div>
          </div>
        </div>
      </div>
    </p-card>
    <div class="relative mx-auto w-full xl:w-[1120px]">
      <div class="flex max-w-full flex-col items-center">
        <div class="w-full">
          <div class="max-w-full p-2">
            <div class="" *ngIf="huddleView">
              <div
                class="about grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-6 md:gap-y-2"
              >
                <div class="col-span-6 md:col-span-6 lg:col-span-3">
                  <div class="flex flex-col gap-2">
                    <p-card styleClass="p-0">
                      <div class="p-3">
                        <div class="pb-3 pt-1">
                          <h2 class="h-level-2">Details</h2>
                        </div>
                        <div class="grow-1 mb-2 flex flex-col">
                          <strong>
                            <span class="mr-2">Participants</span>
                          </strong>
                          <div class="flex items-center">
                            <span
                              *ngIf="
                                huddleView.participantsSummary
                                  .showRsvpedYesParticipants &&
                                huddleView.participantsSummary
                                  .showRsvpedMaybeParticipants
                              "
                            >
                              <span>{{
                                huddleView.participantsSummary
                                  .totalRsvpedYesParticipants +
                                  huddleView.participantsSummary
                                    .totalRsvpedMaybeParticipants +
                                  " going"
                              }}</span>
                            </span>
                            <span
                              *ngIf="
                                huddleView.participantsSummary
                                  .showRsvpedYesParticipants &&
                                huddleView.participantsSummary
                                  .showRsvpedMaybeParticipants
                              "
                              class="h-level-2"
                              >&nbsp;&#8901;&nbsp;</span
                            >
                            <span
                              *ngIf="
                                huddleView.participantsSummary
                                  .showInterestedParticipants
                              "
                            >
                              <span>{{
                                huddleView.participantsSummary
                                  .totalInterestedParticipants + " interested"
                              }}</span>
                            </span>
                            <span
                              *ngIf="
                                huddleView.participantsSummary
                                  .showInterestedParticipants
                              "
                              class="h-level-2"
                              >&nbsp;&#8901;&nbsp;</span
                            >

                            <span
                              *ngIf="
                                huddleView.participantsSummary
                                  .showRequestedParticipants
                              "
                            >
                              <span>{{
                                huddleView.participantsSummary
                                  .totalRequestedParticipants +
                                  " requested/waiting"
                              }}</span>
                            </span>
                            <span
                              *ngIf="
                                huddleView.participantsSummary
                                  .showRequestedParticipants
                              "
                              class="h-level-2"
                              >&nbsp;&#8901;&nbsp;</span
                            >

                            <span
                              *ngIf="
                                huddleView.participantsSummary
                                  .showInvitedParticipants
                              "
                            >
                              <span>{{
                                huddleView.participantsSummary
                                  .totalInvitedParticipants + " invited"
                              }}</span>
                            </span>
                          </div>
                        </div>
                        <div class="grow-1 flex flex-col gap-2">
                          <strong>Hosts and Co-hosts</strong>
                          <p *ngIf="!coHosts.length" class="mb-3">
                            No Hosts and Co-Hosts added
                          </p>
                          <p-avatarGroup
                            styleClass="mb-3"
                            *ngIf="coHosts.length"
                          >
                            <p-avatar
                              *ngFor="let host of coHosts"
                              size="large"
                              shape="circle"
                            >
                              <img
                                *ngIf="host?.profilePicUrl"
                                [src]="
                                  getProfileImageById(host?.id) | image | async
                                "
                                alt=""
                              />
                              <img
                                *ngIf="!host?.profilePicUrl"
                                src="../assets/images/user-pic-default.svg"
                                alt="default"
                              />
                            </p-avatar>
                          </p-avatarGroup>
                        </div>
                        <div class="mb-2 flex items-center gap-1">
                          <strong>Duration:</strong>
                          <span
                            *ngIf="
                              huddleView.startDateTime &&
                              !huddleView.endDateTime
                            "
                            >{{
                              huddleView.startDateTime ? "Open" : "N/A"
                            }}</span
                          >
                          <span
                            *ngIf="
                              huddleView.startDateTime && huddleView.endDateTime
                            "
                            >{{
                              moment(huddleView.endDateTime).diff(
                                huddleView.startDateTime,
                                "days"
                              )
                            }}
                            day</span
                          >
                        </div>
                        <div class="mb-2 flex items-center gap-1">
                          <strong>Activity:</strong>
                          <span>{{ activityMap[huddleView.activity] }}</span>
                        </div>
                        <div class="mb-2 flex items-center gap-1">
                          <strong>Name:</strong>
                          <span>{{ huddleView.name }}</span>
                        </div>
                        <div class="mb-2 flex items-center gap-1">
                          <strong>Privacy:</strong>
                          <span>{{ privacyMap["" + huddleView.privacy] }}</span>
                        </div>
                        <div class="flex flex-col">
                          <strong>Description:</strong>
                          <div
                            class="break-words"
                            *ngIf="huddleView.description"
                          >
                            <app-description-content
                              [contentHTML]="huddleView.description"
                            ></app-description-content>
                          </div>
                          <div *ngIf="!huddleView.description">
                            No description provided
                          </div>
                        </div>
                      </div>
                    </p-card>
                    <p-card *ngIf="huddleView.activity === 'HIKING'">
                      <div class="p-3">
                        <h2 class="h-level-2">External Links</h2>
                      </div>
                      <div class="p-3">
                        <div class="pb-3 pt-1">
                          <div class="mb-2 flex gap-1">
                            <strong>Trial URL: </strong>
                            <span>{{ huddleView.externalTrailUrl }}</span>
                          </div>
                          <div class="mb-2 flex gap-1">
                            <strong>Event URL:</strong>
                            <span>{{ huddleView.externalEventUrl }}</span>
                          </div>
                          <div class="mb-2 flex gap-1">
                            <strong>Photo Album URL:</strong>
                            <span>{{ huddleView.externalPhotoAlbumUrl }}</span>
                          </div>
                          <div class="flex gap-1">
                            <strong>Communication Channel URL:</strong>
                            <span>{{ huddleView.externalCommChannelUrl }}</span>
                          </div>
                        </div>
                      </div>
                    </p-card>
                  </div>
                </div>

                <div class="col-span-6 md:col-span-6 lg:col-span-3">
                  <div class="flex flex-col gap-2">
                    <p-card>
                      <div
                        id="gmap-about"
                        class="gmap-about h-[250px] rounded-md"
                      ></div>
                      <div class="p-3">
                        <div class="pb-3 pt-1">
                          <div
                            class="mb-4 flex flex-col gap-1"
                            *ngIf="huddleView?.venue"
                          >
                            <span class="font-semibold">Venue: </span>
                            <div>
                              <p>{{ huddleView.venue?.name }}</p>
                              <p>{{ huddleView.venue?.formattedAddress }}</p>
                            </div>
                            <div *ngIf="huddleView.venueNote">
                              <span class="block font-semibold"
                                >Venue Note:
                              </span>
                              <span
                                [innerHtml]="
                                  huddleView.venueNote.length > 100
                                    ? huddleView.venueNote.slice(
                                        0,
                                        huddleView.venueNote.length / 6
                                      )
                                    : huddleView.venueNote
                                "
                              ></span>
                              <span
                                *ngIf="
                                  !showFullVenueNote &&
                                  huddleView.venueNote.length > 100
                                "
                                >...</span
                              >
                              <span
                                *ngIf="
                                  showFullVenueNote &&
                                  huddleView.venueNote.length > 100
                                "
                                [innerHtml]="
                                  huddleView.venueNote.slice(
                                    huddleView.venueNote.length / 6 + 1,
                                    huddleView.venueNote.length
                                  )
                                "
                              ></span>
                              <span
                                class="primary cursor-pointer"
                                *ngIf="huddleView.venueNote.length > 100"
                                (click)="showFullVenueNote = !showFullVenueNote"
                                >{{
                                  !showFullVenueNote ? "more" : "less"
                                }}</span
                              >
                            </div>
                          </div>
                          <div
                            class="mb-4 flex flex-col gap-1"
                            *ngIf="huddleView?.meetingPoint"
                          >
                            <span class="font-semibold">Meeting Point: </span>
                            <div>
                              <p>{{ huddleView.meetingPoint?.name }}</p>
                              <p>
                                {{ huddleView.meetingPoint?.formattedAddress }}
                              </p>
                            </div>
                            <div *ngIf="huddleView.meetingPointNote">
                              <span class="block font-semibold"
                                >Meeting Note:
                              </span>
                              <span
                                [innerHtml]="
                                  huddleView.meetingPointNote.length > 100
                                    ? huddleView.meetingPointNote.slice(
                                        0,
                                        huddleView.meetingPointNote.length / 6
                                      )
                                    : huddleView.meetingPointNote
                                "
                              ></span>
                              <span
                                *ngIf="
                                  !showFullMeetingNote &&
                                  huddleView.meetingPointNote.length > 100
                                "
                                >...</span
                              >
                              <span
                                *ngIf="
                                  showFullMeetingNote &&
                                  huddleView.meetingPointNote.length > 100
                                "
                                [innerHtml]="
                                  huddleView.meetingPointNote.slice(
                                    huddleView.meetingPointNote.length / 6 + 1,
                                    huddleView.meetingPointNote.length
                                  )
                                "
                              ></span>
                              <span
                                class="primary cursor-pointer"
                                *ngIf="huddleView.meetingPointNote.length > 100"
                                (click)="
                                  showFullMeetingNote = !showFullMeetingNote
                                "
                                >{{
                                  !showFullMeetingNote ? "more" : "less"
                                }}</span
                              >
                            </div>
                          </div>
                          <div
                            class="flex flex-col gap-1"
                            *ngIf="huddleView?.parking"
                          >
                            <span class="font-semibold">Parking Point: </span>
                            <div>
                              <p>{{ huddleView.parking?.name }}</p>
                              <p>{{ huddleView.parking?.formattedAddress }}</p>
                            </div>
                            <div *ngIf="huddleView.parkingNote">
                              <span class="block font-semibold"
                                >Parking Note:
                              </span>
                              <span
                                [innerHtml]="
                                  huddleView.parkingNote.length > 100
                                    ? huddleView.parkingNote.slice(
                                        0,
                                        huddleView.parkingNote.length / 6
                                      )
                                    : huddleView.parkingNote
                                "
                              ></span>
                              <span
                                *ngIf="
                                  !showFullParkingNote &&
                                  huddleView.parkingNote.length > 100
                                "
                                >...</span
                              >
                              <span
                                *ngIf="
                                  showFullParkingNote &&
                                  huddleView.parkingNote.length > 100
                                "
                                [innerHtml]="
                                  huddleView.parkingNote.slice(
                                    huddleView.parkingNote.length / 6 + 1,
                                    huddleView.parkingNote.length
                                  )
                                "
                              ></span>
                              <span
                                class="primary cursor-pointer"
                                *ngIf="huddleView.parkingNote.length > 100"
                                (click)="
                                  showFullParkingNote = !showFullParkingNote
                                "
                                >{{
                                  !showFullParkingNote ? "more" : "less"
                                }}</span
                              >
                            </div>
                          </div>
                          <div
                            *ngIf="
                              !huddleView?.parking &&
                              !huddleView?.meetingPoint &&
                              !huddleView?.venue
                            "
                          >
                            No Location Selected
                          </div>
                        </div>
                      </div>
                    </p-card>
                    <p-card>
                      <div class="p-3">
                        <h2 class="h-level-2">Additional Information</h2>
                      </div>
                      <div class="p-3">
                        <div class="pb-3 pt-1">
                          <div class="mb-2 flex gap-1">
                            <strong>Payment: </strong>
                            <span>{{
                              paymentTypeMap[huddleView.paymentMode]
                            }}</span>
                          </div>
                          <div class="mb-2 flex gap-1" *ngIf="isCarpoolAllowed">
                            <strong>Carpooling: </strong>
                            <span>Available</span>
                          </div>
                          <div class="mb-2 flex gap-1">
                            <strong>RSVP Start at: </strong>
                            <span>{{
                              huddleView.rsvpStartDateTime
                                | date: "EEEE, MMMM d, y, h:mm a"
                            }}</span>
                          </div>
                          <div class="flex gap-1">
                            <strong>RSVP Ends at: </strong>
                            <span>{{
                              huddleView.rsvpEndDateTime
                                | date: "EEEE, MMMM d, y, h:mm a"
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </p-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-menu
  #menu
  [model]="moreOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
>
  <ng-template pTemplate="item" let-item>
    <a
      *ngIf="['report_huddle', 'add_to_calendar'].includes(item.id)"
      class="p-menuitem-link justify-content-between align-items-center flex p-3"
      (click)="onOverlayMenuClick(item.id)"
    >
      <div>
        <span [class]="item.icon">
          <fa-icon
            [icon]="item.icon"
            size="1x"
            styleClass="highlight-text"
          ></fa-icon>
        </span>
        <span class="ml-2"> {{ item.label }}</span>
      </div>
    </a>
  </ng-template>
</p-menu>

<p-menu
  #going
  [model]="goingOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
>
  <ng-template pTemplate="item" let-item>
    <a
      (click)="rsvp(item.label === 'Tentative' ? 'MAYBE' : 'NO')"
      class="p-menuitem-link p-3 dark:border-[#1F242F] dark:bg-[#1F242F] dark:text-slate-300 hover:dark:bg-[#1F242F]"
    >
      <div class="flex items-center">
        <span
          class="material-icons"
          [ngClass]="
            (rsvpType === 'MAYBE' && item.label === 'Tentative') ||
            (rsvpType === 'NO' && item.label !== 'Tentative')
              ? 'text-teal-500'
              : ''
          "
        >
          {{
            item.label === "Tentative" ? "do_not_disturb_on" : "cancel"
          }}</span
        >
        <span class="ml-2">
          {{
            rsvpType === "NO" && item.label === "Decline"
              ? "Declined"
              : item.label
          }}</span
        >
      </div>
    </a>
  </ng-template>
</p-menu>

<p-menu
  #requested
  [model]="requestedOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
>
  <ng-template pTemplate="item" let-item>
    <a
      (click)="onOverlayMenuClick(item.id)"
      class="p-menuitem-link p-3 dark:border-[#1F242F] dark:bg-[#1F242F] dark:text-slate-300 hover:dark:bg-[#1F242F]"
    >
      <div class="flex items-center">
        <span class="material-icons">cancel</span>
        <span class="ml-2">Withdraw</span>
      </div>
    </a>
  </ng-template>
</p-menu>

<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>

<app-rsvp-dialog
  *ngIf="huddleView"
  [huddleView]="huddleView"
  [userInfo]="userInfo"
  [(visible)]="visible"
  [rsvpType]="selectedRsvpType"
  [width]="
    huddleView.paymentMode === 'PAID' && settings.guestAllowed ? '50vw' : '30vw'
  "
>
</app-rsvp-dialog>
