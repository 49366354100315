<router-outlet></router-outlet>
<p-toast
  [life]="5000"
  [breakpoints]="{
    '920px': { width: '22rem', left: '50%', transform: 'translateX(-50%)' },
  }"
></p-toast>
<p-toast
  [life]="5000"
  key="custom"
  styleClass="post-toast"
  [breakpoints]="{
    '920px': { width: '22rem', left: '50%', transform: 'translateX(-50%)' },
  }"
>
  <ng-template let-message pTemplate="message">
    <div class="flex flex-col gap-2" style="flex: 1">
      <div class="text-left">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p>{{ message.detail }}</p>
      </div>
      <a
        class="cursor-pointer text-black"
        (click)="reloadCurrentRoute(message.data)"
        *ngIf="message.data.objectLabel"
        >View {{ message.data.objectLabel }}</a
      >
    </div>
  </ng-template>
</p-toast>
<div
  *ngIf="isBrowser"
  class="fixed bottom-0 right-0 z-100 flex items-start p-4"
  [ngClass]="{ hidden: !showCookie }"
>
  <div class="flex w-full flex-col items-end">
    <div
      class="mp-cookie w-full max-w-[24rem] overflow-hidden rounded-md shadow-md"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-1">
            <p>
              We use only essential cookies to ensure our website functions
              properly. We do not set any third-party cookies, track your
              browsing, or sell your personal information. For more details, or
              to change your preferences, please see our
              <a
                class="primary"
                target="_blank"
                [href]="sdtkWebUrl + '/policies/cookie'"
                >Cookies Policy</a
              >.
            </p>
            <div class="mt-2">
              <button
                type="button"
                pButton
                (click)="onAcceptAndReject()"
                id="cookie-accept"
                class="mp-button mp-button-filled mb-4 mr-3 truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
              >
                <span>Okay</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
