import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, tap } from 'rxjs';
import { environment } from './../environments/environment';
import { NotificationsService } from './main/services/notifications.service';
import { PlatformService } from './main/services/platform.service';
import { SseService } from './main/services/sse.service';
import { ThemeService } from './main/services/theme.service';
import { UserService } from './main/services/user.service';
import { UserActionType } from './main/store/actions/user.action';
import { AppState } from './main/store/model/state.model';
import { THEME } from './main/store/reducers/app.reducer';
import { NotificationSummary, UserDetails } from './main/types/main.types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [MessageService],
})
export class AppComponent implements OnInit {
  shouldLoad = true;
  showCookie = true;
  isBrowser = true;
  sdtkWebUrl = environment.sdtkWebUrl;
  userDetail$: Observable<UserDetails>;
  notificationSummary$: Observable<NotificationSummary>;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private sseService: SseService,
    private authService: AuthService,
    private userService: UserService,
    private notificationsService: NotificationsService,
    private store: Store<AppState>,
    private swUpdate: SwUpdate,
    private themeService: ThemeService,
    private platformService: PlatformService,
  ) {
    console.log(
      'Running web app in environment=' +
        environment.name +
        ' in the mode production=' +
        environment.production,
    );

    this.isBrowser = this.platformService.getIsBrowser();

    if (this.isBrowser) {
      const _theme = localStorage.getItem('theme');
      if (_theme) {
        this.setTheme(_theme);
      }
    }

    if (this.swUpdate.isEnabled) {
      /*
      Error: src/app/app.component.ts:50:21 - error TS2339: Property 'available' does not exist on type 'SwUpdate'.
      TODO: Uncomment this code when we have fix/solution for the above error.
      this.swUpdate.available.subscribe(() => {
        window.location.reload();
      });
      */
    }
  }

  ngOnInit(): void {
    if (this.platformService.getIsBrowser()) {
      const isCookieAccepted = localStorage.getItem('cookie_accepted');
      if (isCookieAccepted) {
        this.showCookie = false;
      }
      this.authService.isAuthenticated$.subscribe((authenticated: boolean) => {
        if (authenticated) {
          this.getUserInfo();
          // this.getNotificationsSummary();
        }
      });

      const script = this.renderer.createElement('script') as HTMLScriptElement;
      script.innerText =
        '(e=>{var a,o,r,n="The Google Maps JavaScript API",s="google",c="importLibrary",t="__ib__",p=document,i=window,l=(i=i[s]||(i[s]={})).maps||(i.maps={}),m=new Set,d=new URLSearchParams;l[c]?console.warn(n+" only loads once. Ignoring:",e):l[c]=(i,...w)=>m.add(i)&&(a||(a=new Promise((async(c,i)=>{for(r in await(o=p.createElement("script")),d.set("libraries",[...m].concat("places").join(",")),e)d.set(r.replace(/[A-Z]/g,(e=>"_"+e[0].toLowerCase())),e[r]);d.set("callback",s+".maps."+t),o.src=`https://maps.${s}apis.com/maps/api/js?`+d,l[t]=c,o.onerror=()=>a=i(Error(n+" could not load.")),o.nonce=p.querySelector("script[nonce]")?.nonce||"",p.head.append(o)})))).then((()=>l[c](i,...w)))})({v:"weekly",key:"' +
        environment.mapAPIKey +
        '"})';
      this.renderer.appendChild(document.body, script);
    }
  }

  getUserInfo() {
    // this.shouldLoad && (this.isLoading = true);
    this.store.dispatch({
      type: UserActionType.USER_DETAIL_RELOAD,
    });
    this.userService.getUserInfo().subscribe({
      next: (data: any) => {
        console.log('Got user information from the server.');
        data.loading = false;

        // if (data['onboarded']) {
        //   this.getNotificationsSummaryInitial();
        // }

        this.store.dispatch({
          type: UserActionType.USER_DETAIL,
          payload: data as UserDetails,
        });
      },
      error: (e) => {
        console.error(
          'Request to get user information from the server errored out.',
        );
        console.error(e);
        this.router.navigateByUrl('/error');
      },
      complete: () => {
        console.info(
          'Request to get user information from the server completed.',
        );
      },
    });
  }

  // getNotificationsSummary() {
  //   this.sseService
  //     .createFetchEventSource()
  //     .subscribe((summary: NotificationSummary) => {
  //       this.store.dispatch({
  //         type: NotificationType.NOTIFICATION_SUMMARY,
  //         payload: summary as NotificationSummary,
  //       });
  //     });
  // }

  // getNotificationsSummaryInitial() {
  //   this.notificationsService.getNotificationSummary().subscribe({
  //     next: (summary: any) => {
  //       summary.loading = false;
  //       this.store.dispatch({
  //         type: NotificationType.NOTIFICATION_SUMMARY,
  //         payload: summary as NotificationSummary,
  //       });
  //     },
  //     error: (err: Error) => {
  //       console.error(
  //         'Request to get notifications count from the server errored out.',
  //       );
  //       console.error(err.message);
  //     },
  //   });
  // }

  subscribeStore() {
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap(({ loading }) => {
          if (loading) {
            this.shouldLoad = false;
            this.getUserInfo();
          }
        }),
      );
    this.userDetail$.subscribe();

    // this.notificationSummary$ = this.store
    //   .select((store) => store.notifications)
    //   .pipe(
    //     tap(({ loading }) => {
    //       if (loading) {
    //         this.getNotificationsSummary();
    //       }
    //     }),
    //   );
    // this.notificationSummary$.subscribe();
  }

  onAcceptAndReject() {
    this.showCookie = false;
    localStorage.setItem('cookie_accepted', 'true');
  }

  reloadCurrentRoute(data: { id: number; route: string }) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([data.route, 'view', data.id]);
    });
  }

  changeUserTheme(themeValue: string, href: string) {
    themeValue = themeValue?.toLowerCase();
    let media: MediaQueryList;
    let isDark = false;
    if (themeValue !== 'system') {
      this.themeService.switchTheme(href);
      isDark = themeValue.toLowerCase() === 'dark';
    } else {
      media = window.matchMedia('(prefers-color-scheme: light)');
      media.matches
        ? this.themeService.switchTheme('lara-light-teal')
        : this.themeService.switchTheme('lara-dark-teal');
      isDark = !media.matches;
    }
    this.store.dispatch({
      type: THEME,
      payload: isDark,
    });
  }

  setTheme(userTheme: string) {
    const theme = userTheme === 'light' ? 'lara-light-teal' : 'lara-dark-teal';
    if (userTheme !== '') {
      this.changeUserTheme(userTheme, theme);
      localStorage.setItem('theme', userTheme);
    }
  }
}
