<footer class="surface-ground">
  <div
    class="mx-auto mb-3 grid max-w-[1120px] grid-cols-4 gap-x-8 gap-y-8 px-4 py-8 md:gap-y-0"
  >
    <div class="col-span-4 md:col-span-2">
      <div class="flex flex-col">
        <span class="mb-2 flex items-center gap-2">
          <img
            [src]="sdtkWebUrl + '/assets/icons/logo/logo.svg'"
            class="h-10"
            alt="Siddhatek Logo"
          />
          <img
            [src]="sdtkWebUrl + '/assets/icons/logo-text/logo-text-bold.svg'"
            class="h-6"
            alt="Siddhatek Text Logo"
          />
        </span>
        <p class="sub-title mb-3">
          Siddhatek is a leading provider of outdoor adventure and wellness
          solutions. Our mission is to inspire people to embrace the outdoors
          and improve their physical and mental health using AI-driven tools and
          data metrics.
        </p>
        <div class="flex gap-6">
          <p-button
            [rounded]="true"
            [text]="true"
            type="button"
            styleClass="justify-center h-10 w-10 mp-button mp-button-rounded bg-teal-light"
          >
            <img
              [src]="sdtkWebUrl + '/assets/icons/social/facebook.svg'"
              alt="facebook"
            />
          </p-button>
          <p-button
            [rounded]="true"
            [text]="true"
            type="button"
            styleClass="justify-center h-10 w-10 mp-button mp-button-rounded bg-teal-light"
          >
            <img
              [src]="sdtkWebUrl + '/assets/icons/social/insta.svg'"
              alt="facebook"
            />
          </p-button>
          <p-button
            [rounded]="true"
            [text]="true"
            type="button"
            styleClass="justify-center h-10 w-10 mp-button mp-button-rounded bg-teal-light"
          >
            <img
              [src]="sdtkWebUrl + '/assets/icons/social/twitter.svg'"
              alt="facebook"
            />
          </p-button>
          <p-button
            [rounded]="true"
            [text]="true"
            type="button"
            styleClass="justify-center h-10 w-10 mp-button mp-button-rounded bg-teal-light"
          >
            <img
              [src]="sdtkWebUrl + '/assets/icons/social/youtube.svg'"
              alt="youtube"
            />
          </p-button>
        </div>
      </div>
    </div>
    <div class="col-span-2 md:col-span-1">
      <div class="flex flex-col items-start md:items-end">
        <ul role="list">
          <li class="mb-1">
            <a [href]="sdtkWebUrl + '/products/missionpeak'" target="_blank"
              >Mission Peak</a
            >
          </li>
          <li class="mb-1">
            <a [href]="sdtkWebUrl + '/products/missioncorp'" target="_blank"
              >Mission Corp</a
            >
          </li>
          <!-- <li class="mb-1">
            <a [href]="sdtkWebUrl + '/pricing'" target="_blank">Pricing</a>
          </li> -->
          <li class="mb-1">
            <a [href]="sdtkWebUrl + '/about'" target="_blank">Mobile</a>
          </li>
          <li class="mb-1">
            <a [href]="sdtkWebUrl + '/about'" target="_blank">About</a>
          </li>
          <li class="mb-1">
            <a [href]="sdtkWebUrl + '/contact'" target="_blank">Contact</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-span-2 md:col-span-1">
      <div class="flex flex-col items-end">
        <ul role="list">
          <li class="mb-1">What's New</li>
          <li class="mb-1">
            <a [href]="sdtkWebUrl + '/policies/terms'" target="_blank"
              >Terms of Service</a
            >
          </li>
          <li class="mb-1">
            <a [href]="sdtkWebUrl + '/policies/privacy'" target="_blank"
              >Privacy Policy</a
            >
          </li>
          <li class="mb-1">
            <a [href]="sdtkWebUrl + '/policies/cookie'" target="_blank"
              >Cookie Policy</a
            >
          </li>
          <a [href]="sdtkWebUrl + '/supprot'" target="_blank">Support</a>
        </ul>
      </div>
    </div>
  </div>
  <div [ngClass]="isDark ? 'surface-overlay' : 'bg-highlight'">
    <div
      class="mx-auto flex max-w-[1120px] flex-wrap justify-start gap-4 px-4 py-6 md:justify-end"
    >
      <span class="gray-50">© 2024 Siddhatek LLC. All rights reserved</span>
    </div>
  </div>
</footer>
