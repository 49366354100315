import { Action } from '@ngrx/store';
import { NotificationSummary } from '../../types/main.types';

export enum NotificationType {
  NOTIFICATION_SUMMARY = '[NOTIFICATIONSUMMARY] Get Notification Summary',
  NOTIFICATION_SUMMARY_RELOAD = '[NOTIFICATIONSUMMARYRELOAD] Refresh Notification Summary',
}

export class NotificationSummaryAction implements Action {
  readonly type = NotificationType.NOTIFICATION_SUMMARY;
  constructor(public payload: NotificationSummary) {}
}
export type NotificationAction = NotificationSummaryAction;
