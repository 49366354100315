import { Component } from '@angular/core';
import { ThemeService } from '../../main/services/theme.service';
import { environment } from 'src/environments/environment';
import { PlatformService } from 'src/app/main/services/platform.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  isDark = false;

  sdtkWebUrl = environment.sdtkWebUrl;

  constructor(
    readonly themeService: ThemeService,
    private platformService: PlatformService,
  ) {
    if (this.platformService.getIsBrowser()) {
      this.isDark = localStorage.getItem('isDark') === 'true';
      this.themeService.theme$.subscribe((data: boolean) => {
        this.isDark = data;
      });
    }
  }
}
