import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PublicService } from '../public.service';
import { App, PublishInspiration } from 'src/app/main/types/main.types';
import { isValidEmail } from 'src/app/main/utilities';
import { Router } from '@angular/router';
import { PlatformService } from 'src/app/main/services/platform.service';
import { ThemeService } from 'src/app/main/services/theme.service';
import { Observable, tap } from 'rxjs';
import { AppState } from 'src/app/main/store/model/state.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-inspiration-public',
  templateUrl: './inspiration-public.component.html',
})
export class InspirationPublicComponent implements OnInit, AfterViewInit {
  isLoading = false;

  subscriberEmail = '';

  subscribed = false;

  isValidEmail = true;

  isDark = false;

  appState$: Observable<App>;

  publishInspirations: PublishInspiration[] = [];

  constructor(
    private publicService: PublicService,
    private messageService: MessageService,
    private router: Router,
    private store: Store<AppState>,
    private platformService: PlatformService,
    private themeService: ThemeService,
  ) {}

  ngOnInit(): void {
    this.getAppState();
  }

  onSubscribeNewLetter() {
    if (!this.isValidEmail) {
      return;
    }

    this.isLoading = true;
    this.publicService.subscribrNewsLetter(this.subscriberEmail).subscribe({
      next: (data: any) => {
        this.subscribed = !0;
        this.isLoading = false;
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Categories',
          detail: errors ? errors[0] : 'Something went wrong',
        });
        this.isLoading = false;
      },
    });
  }

  validateEmail(e: any) {
    if (e.target.value !== '' && !isValidEmail(e.target.value)) {
      this.isValidEmail = false;
    } else {
      this.isValidEmail = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.platformService.getIsBrowser()) {
      const _dark = localStorage.getItem('isDark');
      if (_dark) {
        this.isDark = _dark === 'true' ? !0 : !1;
      }
      this.themeService.theme$.subscribe((data: boolean) => {
        this.isDark = data;
      });
    }
  }

  getAppState() {
    this.appState$ = this.store
      .select((store) => store.app)
      .pipe(
        tap((appState: App) => {
          this.isDark = appState.isDark;
        }),
      );
    this.appState$.subscribe();
  }

  getStarted() {
    this.router.navigate(['/inspiration', 'authored']);
  }
}
