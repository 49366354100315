import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { authGuard } from './utilities';

const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    component: MainComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./views/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'huddle',
        loadChildren: () =>
          import('./views/huddle/huddle.module').then((m) => m.HuddleModule),
      },
      {
        path: 'carpool',
        loadChildren: () =>
          import('./views/carpool/carpool.module').then((m) => m.CarpoolModule),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./views/account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'setting',
        loadChildren: () =>
          import('./views/setting/setting.module').then((m) => m.SettingModule),
      },
      {
        path: 'notification',
        loadChildren: () =>
          import('./views/notifications/notifications.module').then(
            (m) => m.NotificationsModule,
          ),
      },
      {
        path: 'community',
        loadChildren: () =>
          import('./views/community/community.module').then(
            (m) => m.CommunityModule,
          ),
      },
      {
        path: 'inspiration',
        loadChildren: () =>
          import('./views/inspiration/inspiration.module').then(
            (m) => m.InspirationModule,
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
