import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { HuddleService } from 'src/app/main/services/huddle.service';
import { AppState } from 'src/app/main/store/model/state.model';
import { APPBARTITLE } from 'src/app/main/store/reducers/app.reducer';
import { DEFAULT_HOME } from 'src/app/main/utilities';

enum ObjectType {
  C = 'CARPOOL',
  H = 'HUDDLE',
  I = 'INSPIRATION',
  CM = 'COMMUNITY',
  P = 'POST',
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
})
export class NavComponent implements OnInit {
  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private huddleService: HuddleService,
    private router: Router,
    private store: Store<AppState>,
  ) {
    const navUID = this.route.snapshot.paramMap.get('id');

    // MP-301 - Required for redirecting to shared nav link after new signup
    localStorage.setItem('navUID', navUID || '');

    this.huddleService.getLink(navUID).subscribe({
      next: (data: any) => {
        const { objectId, objectType } = data;
        switch (objectType) {
          case ObjectType.C:
            this.router.navigate(['carpool', 'view', 'new', objectId]);
            break;
          case ObjectType.H:
            this.router.navigate(['huddle', 'view', objectId]);
            break;
          case ObjectType.I:
            this.router.navigate(['inspiration', 'view', objectId]);
            break;
          case ObjectType.CM:
            this.router.navigate(['community', 'view', objectId]);
            break;
          case ObjectType.P:
            this.router.navigate([DEFAULT_HOME, 'timeline', 'view', objectId]);
            break;
        }
      },
    });
  }

  ngOnInit(): void {
    this.store.dispatch({
      type: APPBARTITLE,
      payload: 'Navigation',
    });
  }
}
