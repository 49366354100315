import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PublicComponent } from './public.component';
import { ErrorComponent } from './error/error.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { HealthComponent } from './health/health.component';
import { StartComponent } from './start/start.component';
import { MobileComponent } from './mobile/mobile.component';
import { InspirationPublicComponent } from './inspiration-public/inspiration-public.component';
import { InspirationPublicViewComponent } from './inspiration-public-view/inspiration-public-view.component';
import { HuddleViewPublicComponent } from './huddle-public-view/huddle-public-view.component';
import { CommunityPublicViewComponent } from './community-public-view/community-public-view.component';
import { JoinComponent } from './join/join.component';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      {
        path: '',
        redirectTo: 'start',
        pathMatch: 'full',
      },
      {
        path: 'health',
        component: HealthComponent,
      },
      {
        path: 'start',
        component: StartComponent,
      },
      {
        path: 'mobile',
        component: MobileComponent,
      },
      {
        path: 'signup',
        component: SignUpComponent,
      },
      {
        path: 'signin',
        component: SignInComponent,
      },
      {
        path: 'error',
        component: ErrorComponent,
      },
      {
        path: 'explore/inspiration',
        component: InspirationPublicComponent,
        data: {
          description: 'Inspiration Description',
        },
      },
      {
        path: 'explore/inspiration/:id',
        component: InspirationPublicViewComponent,
      },
      {
        path: 'join/:uid',
        component: JoinComponent,
      },
      {
        path: 'join/huddle/:id',
        component: HuddleViewPublicComponent,
      },
      {
        path: 'join/community/:id',
        component: CommunityPublicViewComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
