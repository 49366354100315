import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { MenuItem, MessageService } from 'primeng/api';
import { ImagePipe } from 'src/app/main/pipes/image.pipe';
import { UserService } from 'src/app/main/services/user.service';
import {
  Post,
  PublicUserDetails,
  User,
  UserDetails,
} from 'src/app/main/types/main.types';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/main/store/model/state.model';
import { environment } from 'src/environments/environment';
import { publicProfileOption } from 'src/app/main/utilities/overlayHelper';
import { ConnectionsService } from 'src/app/main/services/connections.service';
import { Observable, Subject, tap } from 'rxjs';
import { APPBARTITLE } from 'src/app/main/store/reducers/app.reducer';

@Component({
  selector: 'app-profile-public',
  templateUrl: './profile-public.component.html',
})
export class ProfilePublicComponent implements OnInit {
  tabs: MenuItem[] | undefined;
  activeTab: MenuItem | undefined;
  userDetails: PublicUserDetails;
  userInfo: UserDetails;
  isLoading = false;
  username: string | null;
  follower = false;
  following = false;
  isFollowRequested = false;
  currentPage = 1;
  userPosts: Post[] = [];
  publicProfileOption = publicProfileOption;
  mutualConnections: User[];
  totalMutualConnections: number;
  profilePic: string | null = null;
  isSelfView = false;
  userPickerVisible = false;
  coverPhotoUrl: string | null = null;
  userDetail$: Observable<UserDetails>;
  userPickerEvent: Subject<any> = new Subject<any>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private imagePipe: ImagePipe,
    private messageService: MessageService,
    private clipboard: Clipboard,
    private store: Store<AppState>,
    private connectionsService: ConnectionsService,
  ) {}

  ngOnInit(): void {
    this.username = this.route.snapshot.paramMap.get('username');
    this.getUserInfo();
    this.getMutualConnections();
    this.getUsersPost();
    this.store.dispatch({
      type: APPBARTITLE,
      payload: 'User Profile',
    });
  }

  onActiveItemChange(e: any) {
    this.activeTab = e;
  }

  getUserInfo() {
    this.isLoading = true;
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.userInfo = user;
          const menu = [
            { label: 'Posts' },
            { label: 'About' },
            { label: 'Awards' },
            { label: 'Trends' },
          ];
          this.tabs = [];
          menu.forEach((t: MenuItem, i: number) => {
            if (
              !user.preference.betaUser &&
              ['Awards', 'Trends'].includes(t.label as string)
            ) {
              return;
            }
            this.tabs?.push(t);
          });
          this.activeTab = this.tabs[0];
          this.getPublicUserInfo(user.username);
        }),
      );
    this.userDetail$.subscribe();
  }

  getUsersPost() {
    this.userService
      .getUsersPost(this.username as string, this.currentPage - 1)
      .subscribe({
        next: (data: any) => {
          const _list = data.content.map((d: Post) => {
            if (d.type === 'REPOST') {
              d.refPost.parameters.forEach((p: any) => {
                if (d.refPost.content)
                  d.refPost.content = d.refPost.content.replace(
                    new RegExp(`{{(${p.key})}}`, 'gi'),
                    `<strong>${p.value}</strong>`,
                  );
              });
            } else {
              d.parameters.forEach((p: any) => {
                if (d.content)
                  d.content = d.content.replace(
                    new RegExp(`{{(${p.key})}}`, 'gi'),
                    `<strong>${p.value}</strong>`,
                  );
              });
            }
            return d;
          });
          this.userPosts = [...this.userPosts, ..._list];
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'User Post',
            detail: err.error.message,
          });
        },
      });
  }

  getPublicUserInfo(username: string) {
    this.userService.getUserPublicProfile(this.username).subscribe({
      next: (data: any) => {
        this.userDetails = data;
        this.isSelfView = data.username === username;
        this.follower = data.follower;
        this.following = data.following;

        if (data.coverPhotoUrl) {
          this.imagePipe
            .transform(environment.apiUrl + '/api/user/' + data.id + '/cover')
            .subscribe((image: string | null) => {
              this.coverPhotoUrl = image as string;
            });
        }

        if (data.profilePicUrl)
          this.imagePipe
            .transform(environment.apiUrl + '/api/user/' + data.id + '/pic')
            .subscribe((image: string | null) => {
              this.profilePic = image as string;
            });
        this.isLoading = false;
      },
      error: (e) => {
        console.error(
          "Request to get user's public info from the server errored out",
        );
        this.isLoading = false;
        this.router.navigate(['/404']);
      },
      complete: () =>
        console.info(
          "Request to get user's public info from the server completed.",
        ),
    });
  }

  getMutualConnections() {
    this.connectionsService.getMutualConnections(this.username).subscribe({
      next: (data: any) => {
        this.mutualConnections = data.content;
        this.totalMutualConnections = data.numberOfElements;
      },
      error: (e) => {
        console.error(
          'Request to get mutual connections from the server errored out.',
        );
        this.isLoading = false;
      },
    });
  }

  unFollow() {
    this.connectionsService.unfollow(this.userDetails.id).subscribe({
      next: () => {
        this.userDetails.following = false;
        this.userDetails.followingRequested = false;
        this.messageService.add({
          severity: 'success',
          detail: `You unfollow the ${this.userDetails.displayName}`,
          summary: 'Unfollow',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          detail: 'Unfollow',
          summary: 'Error while unfollow the user',
        });
        console.error(err.message);
      },
    });
  }

  followUser() {
    this.userDetails.loading = true;
    this.connectionsService.followUser(this.userDetails.id).subscribe({
      next: (data: any) => {
        // Remove the user from the list of users to follow
        this.userDetails.following =
          data.connectionState !== 'CONNECT_REQUESTED';
        this.userDetails.followingRequested =
          data.connectionState === 'CONNECT_REQUESTED';
        this.isFollowRequested =
          data.connectionState === 'CONNECT_REQUESTED' ? !0 : !1;
        this.userDetails.loading = false;
        this.messageService.add({
          severity: 'success',
          detail: `You followed the ${this.userDetails.displayName}`,
          summary: 'Follow',
        });
      },
      error: (err) => {
        console.error('Request to follow user errored out.' + err.message);
        this.messageService.add({
          severity: 'error',
          detail: 'Follow',
          summary: 'Error while unfollow the user',
        });
      },
    });
  }

  onMenuClick(id: string) {
    if (id === 'unfollow') {
      this.unFollow();
    }

    if (id === 'copy_profile_link') {
      const copied = this.clipboard.copy(window.location.href);
      if (copied) {
        this.messageService.add({
          severity: 'success',
          summary: 'Copy',
          detail: 'Profile url copied',
        });
      }
    }
  }

  getProfileImage(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  onScrolledDown() {
    this.currentPage++;
    this.getUsersPost();
  }

  onPostDelete(id: any) {
    this.userPosts = this.userPosts.filter((p: Post) => p.id !== id);
  }

  sharePost(e: any) {
    this.userPickerVisible = e;
    this.userPickerEvent.next('onLoad');
  }

  exit() {
    this.router.navigate(['/account']);
  }
}
