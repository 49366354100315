import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { carouselPublicOptions } from 'src/app/main/utilities/overlayHelper';
import { AuthService } from '@auth0/auth0-angular';
import { Carousel } from 'primeng/carousel';
import { ThemeService } from 'src/app/main/services/theme.service';
import { environment } from 'src/environments/environment';
import { PlatformService } from 'src/app/main/services/platform.service';
import { DEFAULT_HOME } from 'src/app/main/utilities';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
})
export class StartComponent implements OnInit {
  isDark = false;

  isBrowser = false;

  carouselOptions = carouselPublicOptions;

  sdtkWebUrl = environment.sdtkWebUrl;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public authService: AuthService,
    readonly themeService: ThemeService,
    private platformService: PlatformService,
  ) {
    this.isBrowser = this.platformService.getIsBrowser();
    if (this.platformService.getIsBrowser()) {
      this.isDark = localStorage.getItem('isDark') === 'true';
      this.themeService.theme$.subscribe((data: boolean) => {
        this.isDark = data;
      });
    }
    Carousel.prototype.onTouchMove = () => {};
  }

  ngOnInit(): void {
    if (this.platformService.getIsBrowser()) {
      const media = window.matchMedia('(prefers-color-scheme: light)');
      this.toggleTheme(media);

      media.addEventListener('change', (e: MediaQueryListEvent) => {
        this.toggleTheme(e);
      });
    }
  }

  signUp() {
    this.authService.loginWithRedirect({
      appState: { target: DEFAULT_HOME },
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  }

  signIn() {
    this.authService.loginWithRedirect({
      appState: { target: DEFAULT_HOME },
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    });
  }

  toggleTheme(e?: MediaQueryList | MediaQueryListEvent) {
    if (e?.matches) {
      this.document.firstElementChild?.classList.remove('dark');
      this.document.firstElementChild?.classList.add('light');
    } else if (!e?.matches) {
      // console.log(this.document);
      this.document.firstElementChild?.classList.add('dark');
      this.document.firstElementChild?.classList.remove('light');
    }
  }
}
