import { NotificationSummary } from '../../types/main.types';
import { NotificationType } from '../actions/notification.action';

const initialState: NotificationSummary = {
  id: '',
  countNewTotal: 0,
  countSinceLastSeen: 0,
  lastSeenAt: '',
};

export function NotificationReducer(
  state: NotificationSummary = initialState,
  action: any,
) {
  switch (action.type) {
    case NotificationType.NOTIFICATION_SUMMARY:
      return { ...state, ...action.payload };
    case NotificationType.NOTIFICATION_SUMMARY_RELOAD:
      return { ...state, loading: true };
    default:
      return state;
  }
}
