import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';

import { UsersComponent } from './users.component';
import { ProfilePublicComponent } from './profile/profile-public.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { NavComponent } from './nav/nav.component';

import { NotFoundComponent } from '../public/notfound/notfound.component';
import { authGuard } from '../main/utilities';

const routes: Routes = [
  {
    path: '',
    component: UsersComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'verify/email',
        component: VerifyEmailComponent,
      },
      {
        path: 'nav/:id',
        component: NavComponent,
      },
      {
        path: '404',
        component: NotFoundComponent,
      },
      {
        path: ':username',
        component: ProfilePublicComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
