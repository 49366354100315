import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMap } from '@angular/google-maps';

import { HomeModule } from './views/home/home.module';
import { SharedModule } from '../shared/shared.module';
import { MainRoutingModule } from './main-routing.module';

import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
// import { EditorModule } from 'primeng/editor';
import { FocusTrapModule } from 'primeng/focustrap';
import { ImageModule } from 'primeng/image';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { MessageService } from 'primeng/api';
import { BillingComponent } from './views/setting/billing/billing.component';
import { AppbarComponent } from './layout/appbar/appbar.component';

import { MainComponent } from './main.component';
import { Sidenav2Component } from './layout/sidenav2/sidenav2.component';
import { HuddleModule } from './views/huddle/huddle.module';
import { SettingModule } from './views/setting/setting.module';
import { AccountModule } from './views/account/account.module';
import { NotificationsModule } from './views/notifications/notifications.module';
import { TabMenuModule } from 'primeng/tabmenu';
import { AvatarModule } from 'primeng/avatar';
import { AccordionModule } from 'primeng/accordion';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { BadgeModule } from 'primeng/badge';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { IntegrationComponent } from './views/setting/integration/integration.component';

// store
import { CarpoolModule } from './views/carpool/carpool.module';

@NgModule({
  declarations: [
    MainComponent,
    BillingComponent,
    IntegrationComponent,
    AppbarComponent,
    Sidenav2Component,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MainRoutingModule,
    SharedModule,
    HomeModule,
    HuddleModule,
    CarpoolModule,
    AccountModule,
    SettingModule,
    NotificationsModule,
    MenubarModule,
    OverlayPanelModule,
    ProgressSpinnerModule,
    StepsModule,
    ToastModule,
    ButtonModule,
    TooltipModule,
    DividerModule,
    SidebarModule,
    FontAwesomeModule,
    RippleModule,
    MenuModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
    InputTextModule,
    // EditorModule,
    CalendarModule,
    CardModule,
    FocusTrapModule,
    TieredMenuModule,
    RadioButtonModule,
    InputNumberModule,
    InputSwitchModule,
    CheckboxModule,
    DialogModule,
    ImageModule,
    ChipModule,
    TableModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatDividerModule,
    ImageCropperModule,
    TabMenuModule,
    AvatarModule,
    AccordionModule,
    ScrollPanelModule,
    BadgeModule,
    ConfirmDialogModule,
    MessagesModule,
    GoogleMap,
  ],
  providers: [MessageService, provideHttpClient(withInterceptorsFromDi())],
})
export class MainModule {}
