import { UserDetails } from '../../types/main.types';
import { UserActionType } from '../actions/user.action';

const initialState: UserDetails = {
  username: '',
  firstName: '',
  lastName: '',
  displayName: '',
  email: '',
  emailVerified: false,
  mobile: '',
  mobileVerified: false,
  onboarded: false,
  activated: false,
  verified: false,
  dateOfBirth: '',
  coverPhotoUrl: '',
  id: 0,
  locale: '',
  profilePicUrl: '',
  timeZone: '',
  uid: '',
  theme: '',
  gender: '',
  age: 0,
  location: '',
  detail: {
    id: 0,
    bio: '',
    weight: 0,
    height: 0,
    interestTags: '',
  },
  preference: {
    betaUser: false,
    connectionApprovalRequired: false,
    devUser: false,
    id: 0,
    lenght: '',
    mass: '',
    temperature: '',
    time: '',
  },
  coverPhotoCoordinate: {
    id: 0,
    rotationAngle: 0,
    scale: 0,
    xcoordinate: 0,
    ycoordinate: 0,
  },
};

export function UserReducer(state: UserDetails = initialState, action: any) {
  switch (action.type) {
    case UserActionType.USER_DETAIL:
      return { ...state, ...action.payload };
    case UserActionType.USER_DETAIL_RELOAD:
      return { ...state, loading: true };
    case UserActionType.LOADING:
      return { loading: action.payload };
      break;
    default:
      return state;
  }
}
