import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
})
export class UsersComponent {
  isVerifyPage = false;

  constructor(private route: Router) {
    if (this.route.url.includes('/verify/email')) {
      this.isVerifyPage = true;
    }
  }
}
