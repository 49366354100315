<div class="flex h-full flex-row">
  <div class="flex w-20 flex-col">
    <div class="mp-border flex h-20 items-center border-b p-3">
      <img
        [src]="
          !isDark
            ? '../assets/icons/logo-dark-1024w.svg'
            : '../assets/icons/logo-light-1024w.svg'
        "
        [srcset]="
          !isDark
            ? '../assets/icons/logo-dark-512w.svg'
            : '../assets/icons/logo-light-512w.svg'
        "
        alt="logo"
        class="h-12"
      />
    </div>
    <div class="flex flex-col">
      <nav>
        <ul role="list" class="sidenav flex flex-col items-center">
          <li
            pRipple
            *ngFor="let menu of menus"
            tooltipPosition="{{ menu.tooltipPosition }}"
            pTooltip="{{ menu.tooltipText }}"
            (click)="onSideNavMenuClick(menu.id)"
            class="menu-item menu-item--text w-full cursor-pointer px-1 py-4 transition-all duration-200"
            [ngClass]="{
              'selected selected-border': activeMenu === menu.id,
              hidden: menu.feature === 'DEV' && !user.preference.devUser,
            }"
          >
            <a
              pRipple
              [routerLink]="menu.routerLink"
              class="m-auto flex w-12 cursor-pointer justify-center gap-x-3 rounded-[50px] leading-6 transition-all duration-200"
            >
              <fa-icon
                [icon]="menu.icon"
                size="lg"
                class="sidenav-icon"
                [ngClass]="isDark ? 'surface-teal-300' : 'highlight-text'"
              ></fa-icon>
            </a>
            <span
              class="sidebar-helptext block text-center font-semibold"
              [ngClass]="isDark ? 'surface-teal-300' : 'highlight-text'"
              >{{ menu.tooltipText }}</span
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="mp-border flex w-80 flex-col border-l">
    <div class="mp-border flex h-20 items-center border-b p-5">
      <img
        class="h-10"
        [src]="
          isDark
            ? '../assets/icons/logo-text-light-beta.svg'
            : '../assets/icons/logo-text-dark-beta.svg'
        "
        alt="Mission Peak"
      />
    </div>
    <div class="flex flex-col">
      <div class="flex h-16 shrink items-center px-4">
        <h2 class="h-level-2">{{ currentMenu }}</h2>
      </div>
      <nav class="flex flex-1 flex-col">
        <ul
          role="list"
          class="flex flex-1 flex-col transition ease-in-out [&>:not(:first-child)]:mt-2"
          *ngIf="activeSubMenus"
        >
          <li
            *ngFor="let submenu of activeSubMenus.submenu"
            (click)="onSideNavSubMenuClick(submenu.id)"
            [ngClass]="
              submenu.feature === 'DEV' && !user.preference.devUser
                ? 'hidden'
                : ''
            "
          >
            <a
              (click)="
                onRouteChange(submenu.routerLink, submenu.queryParams || {})
              "
              class="menu-item flex cursor-pointer flex-col p-2 px-4 text-sm font-semibold leading-6 transition-all duration-200"
              [ngClass]="{ 'selected-text': activeSubMenu === submenu.id }"
            >
              <div class="flex items-center justify-between">
                <div class="flex flex-col">
                  <div class="text-base">{{ submenu.text }}</div>
                  <div class="sub-title">
                    {{ submenu.description }}
                  </div>
                </div>
                <p-badge
                  *ngIf="submenu.showBadge"
                  [value]="'' + notificationCount"
                  severity="danger"
                ></p-badge>
              </div>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
