[
  {
    "path": "/huddle",
    "heading": "Huddle",
    "content": [
      {
        "order": 1,
        "title": "What is a Huddle?",
        "description": "Huddle can have various meanings depending on the context. In MissionPeak App, a huddle is an event where like-minded individuals come together to explore nature, enjoy outdoor activities, and connect with others. Whether it's hiking, trekking, or simply appreciating the beauty of the outdoors, each huddle offers a chance to unplug, recharge, and experience the great outdoors in a shared adventure.",
        "link": "https://missionpeak.app/inspiration/view/1502"
      },
      {
        "order": 2,
        "title": "Creating a Huddle: Organizing Activity-Based Outdoor Events",
        "description": "A huddle is an activity-based event that brings people together for outdoor experiences like hiking or general gatherings. When creating a huddle, you first choose the activity type, such as \"General\" or \"Hiking,\" which enables activity-specific features tailored to that event. You then provide essential details like the event name, date, time, and venue. Additionally, advanced settings allow you to choose whether the huddle is free or paid, customize RSVP open and close dates, and specify parking locations and meeting points along with the venue. This flexibility ensures every huddle is well-organized and tailored to participants' needs.",
        "link": null
      }
    ]
  },
  {
    "path": "/community",
    "heading": "Community",
    "content": [
      {
        "order": 1,
        "title": "What is a Community?",
        "description": "In the MissionPeak App, a community is a group of individuals who come together to share their interests and passions, particularly around outdoor activities. Communities enable members to interact, organize events (which we call huddles), and engage in discussions, making it easy to connect and collaborate with like-minded people. With features designed to support group communication and event/huddle planning, the community structure fosters engagement and active participation in outdoor adventures and lifestyle activities.",
        "link": "https://missionpeak.app/inspiration/view/1502"
      }
    ]
  },
  {
    "path": "/inspiration",
    "heading": "Inspiration",
    "content": [
      {
        "order": 1,
        "title": "What is an Inspiration?",
        "description": "In the MissionPeak App, an inspiration is a platform where users can share articles or blogs about their outdoor experiences, plans, and trail information. This space is open to all, including casual outdoor enthusiasts and seasoned pros, as well as professionals like doctors, nutritionists, and physical therapists. These experts can provide valuable insights by sharing medical tips, health benefits of outdoor activities, and relevant statistics. Inspirations encourage knowledge sharing within the community, fostering a culture of exploration and wellness that motivates others to engage in outdoor adventures and adopt an active lifestyle.",
        "link": "https://missionpeak.app/inspiration/view/1502"
      }
    ]
  }
]
