import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  PRIMARY_OUTLET,
  Router,
  UrlSegmentGroup,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from 'src/app/main/store/model/state.model';
import {
  SideNavMenus,
  menus,
  navigationMap,
  routeMap,
  submenuRouteMap,
} from 'src/app/main/utilities/sidebarHelper';
import { APPBARTITLE } from '../../store/reducers/app.reducer';
import { App, NotificationSummary, UserDetails } from '../../types/main.types';

@Component({
  selector: 'app-sidenav2',
  templateUrl: './sidenav2.component.html',
})
export class Sidenav2Component implements OnInit {
  activeMenu = 'dashboard';

  isDark = false;

  currentMenu = 'Dashboard';

  isSideAndSubMenuClick = false;

  menus: SideNavMenus[] = menus;

  notification$: Observable<NotificationSummary>;

  activeSubMenus: SideNavMenus | undefined = menus.find(
    (menu) => menu.id === this.activeMenu,
  );

  activeSubMenu: string | undefined = this.activeSubMenus?.submenu
    ? this.activeSubMenus?.submenu[0].id
    : '';

  user: UserDetails;

  notificationCount = 0;

  userDetail$: Observable<UserDetails>;

  appState$: Observable<App>;

  @Input() tieredMenuClicked: { link: [] | undefined; label: string };

  @Input() desktop = true;

  @Output() subMenuClick = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private store: Store<AppState>,
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.activeNavigation();
      }
    });
  }

  ngOnInit(): void {
    this.activeNavigation();
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.user = user;
        }),
      );
    this.userDetail$.subscribe();

    this.appState$ = this.store
      .select((store) => store.app)
      .pipe(
        tap((appState: App) => {
          this.isDark = appState.isDark;
        }),
      );
    this.appState$.subscribe();

    this.notification$ = this.store
      .select((store) => store.notifications)
      .pipe(
        tap(({ countNewTotal }) => {
          this.notificationCount = countNewTotal;
        }),
      );
    this.notification$.subscribe();
  }

  onSideNavMenuClick(id: string) {
    this.activeMenu = id;
    this.currentMenu =
      this.activeMenu.charAt(0).toUpperCase() + this.activeMenu.slice(1);
    this.activeSubMenus = menus.find((menu) => menu.id === id);
    const _submenu: SideNavMenus[] | undefined =
      this.activeSubMenus?.submenu || [];
    _submenu.length && (this.activeSubMenu = _submenu[0].id);
    this.store.dispatch({
      type: APPBARTITLE,
      payload: this.currentMenu,
    });
    this.router.navigate([navigationMap[id]]);
  }

  onSideNavSubMenuClick(id: string) {
    this.activeSubMenu = id;
    this.subMenuClick.emit(true);
  }

  onRouteChange(route: any, params: Record<string, string>) {
    const hasParams = Object.keys(params).length;
    if (route) {
      hasParams &&
        this.router.navigate(route, {
          relativeTo: this.route,
          queryParams: params,
        });
      !hasParams && this.router.navigate(route, { relativeTo: this.route });
    }
  }

  activeNavigation() {
    let _url = window.location.pathname;
    let endIndexParams = 0;
    const routeKeys = Object.keys(routeMap);
    const hasQueryParams = this.route.snapshot.queryParamMap.keys.length;

    if (hasQueryParams) {
      endIndexParams = this.router.url.indexOf('?');
    }

    if (this.router.url.includes('view')) {
      const tree: UrlTree = this.router.parseUrl(this.router.url);
      const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
      const [s]: any = g.segments;
      _url = '/' + s.path;
    } else {
      _url = hasQueryParams
        ? this.router.url.substring(0, endIndexParams)
        : this.router.url.replace(/\/\d+/g, '');
    }
    this.activeMenu = routeMap[_url];
    this.activeSubMenu = submenuRouteMap[_url];
    if (this.activeMenu) {
      this.currentMenu =
        this.activeMenu.charAt(0).toUpperCase() + this.activeMenu.slice(1);
      this.activeSubMenus = menus.find((menu) => menu.id === this.activeMenu);
      this.store.dispatch({
        type: APPBARTITLE,
        payload: this.currentMenu,
      });
    }
  }
}
