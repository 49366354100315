import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DEFAULT_HOME } from 'src/app/main/utilities';
import { SettingsService } from 'src/app/main/services/settings.service';
import { AppState } from 'src/app/main/store/model/state.model';
import { Store } from '@ngrx/store';
import { UserDetails } from 'src/app/main/types/main.types';
import { UserActionType } from '../../main/store/actions/user.action';
import { UserService } from 'src/app/main/services/user.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
})
export class VerifyEmailComponent {
  isLoading = false;
  isDashboardLoading = false;
  constructor(
    private settingsService: SettingsService,
    private messageService: MessageService,
    private router: Router,
    private store: Store<AppState>,
    private userService: UserService,
  ) {}

  sendEmailVerificationInstructions() {
    this.isLoading = true;
    this.settingsService.sendEmailVerificationInstructions().subscribe({
      next: (data: any) => {
        this.messageService.add({
          severity: data.error ? 'error' : 'success',
          summary: 'Email Verification',
          detail: data.message,
        });
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err.error.message);
        this.messageService.add({
          severity: 'error',
          summary: 'Email Verification',
          detail: err.error.message,
        });
        this.isLoading = false;
      },
    });
  }

  getUserInfo() {
    // this.shouldLoad && (this.isLoading = true);
    // this.store.dispatch({
    //   type: UserActionType.USER_DETAIL_RELOAD,
    // });
    this.userService.getUserInfo().subscribe({
      next: (data: any) => {
        console.log('Got user information from the server.');
        data.loading = false;
        // this.store.dispatch({
        //   type: UserActionType.USER_DETAIL,
        //   payload: data as UserDetails,
        // });
        if (data['emailVerified'] !== true) {
          this.messageService.add({
            severity: 'error',
            summary: 'Email Verification Required',
            detail: 'Please verify your email.',
          });
          this.isDashboardLoading = false;
        } else if (data['onboarded'] !== true) {
          this.router.navigateByUrl('/onboard');
        } else {
          this.router.navigate([DEFAULT_HOME]);
        }
      },
      error: (e) => {
        console.error(
          'Request to get user information from the server errored out.',
        );
        console.error(e);
        this.router.navigateByUrl('/error');
      },
      complete: () => {
        console.info(
          'Request to get user information from the server completed.',
        );
      },
    });
  }

  redirect() {
    this.isDashboardLoading = true;
    this.getUserInfo();
  }
}
