<div
  class="container"
  *ngIf="authService.isLoading$ | async; else loaded"
></div>

<ng-template #loaded>
  <main class="main">
    <div class="surface-ground notifications-container">
      <div class="mx-auto">
        <section class="surface-overlay mb-4 pb-4 pt-2">
          <div class="flex">
            <p-carousel
              [value]="carouselOptions"
              [autoplayInterval]="5000"
              [showNavigators]="false"
              [numVisible]="1"
              [numScroll]="1"
              *ngIf="isBrowser"
            >
              <ng-template let-product pTemplate="item">
                <div class="mx-auto flex max-w-[1120px] justify-center py-4">
                  <div class="grid grid-cols-2 gap-y-5">
                    <div class="col-span-2 px-3">
                      <h1 class="h-level-1 highlight-text">
                        {{ product.heading }}
                      </h1>
                      <h1 class="h-level-2 highlight-text mb-2 opacity-80">
                        {{ product.subheading }}
                      </h1>
                      <p class="sub-title mb-8">
                        {{ product.description }}
                      </p>
                      <a
                        [href]=""
                        target="_blank"
                        (click)="signIn()"
                        class="mp-button mp-button-filled cursor-pointer rounded px-4 py-3"
                      >
                        Get Started
                      </a>
                    </div>
                    <div class="col-span-2 mt-2">
                      <p-image
                        [src]="
                          !isDark
                            ? product.leftLightImage
                            : product.leftDarkImage
                        "
                        styleClass="flex justify-center grow-0 shrink-1"
                      ></p-image>
                    </div>
                  </div>
                  <div class="hidden md:block">
                    <p-image
                      [src]="
                        !isDark
                          ? product.rightLightImage
                          : product.rightDarkImage
                      "
                      styleClass=""
                    ></p-image>
                  </div>
                </div>
              </ng-template>
            </p-carousel>
          </div>
        </section>
        <section class="my-16 px-4 xl:px-0">
          <h1 class="h-level-1 highlight-text pb-3 text-center">
            Featured and Rated #1
          </h1>
          <h2 class="h-level-2 primary mb-6 text-center">
            Most loved by adventures and influncers. Read more about us at...
          </h2>
          <div class="flex flex-wrap justify-center gap-4 md:gap-16">
            <div class="flex flex-col items-center md:flex-row">
              <span class="mr-2 flex h-[80px] w-[120px]"
                ><img
                  [src]="
                    sdtkWebUrl + '/assets/icons/newspapers/bloomberg-1.svg'
                  "
                  [ngClass]="isDark ? 'invert-[1]' : ''"
                  alt=""
              /></span>
            </div>
            <div class="flex flex-col items-center md:flex-row">
              <span class="mr-2 flex h-[80px] w-[80px]"
                ><img
                  [src]="sdtkWebUrl + '/assets/icons/newspapers/time-2.svg'"
                  alt=""
              /></span>
            </div>
            <div class="flex flex-col items-center md:flex-row">
              <span class="mr-2 flex h-[80px] w-[80px]"
                ><img
                  [src]="sdtkWebUrl + '/assets/icons/newspapers/cnn-1.svg'"
                  alt=""
              /></span>
            </div>
            <div class="flex flex-col items-center md:flex-row">
              <span class="mr-2 flex h-[80px] w-[150px] items-center"
                ><img
                  [src]="sdtkWebUrl + '/assets/icons/newspapers/daily-mail.svg'"
                  [ngClass]="isDark ? 'invert-[1]' : ''"
                  alt=""
              /></span>
            </div>
          </div>
        </section>
        <section class="surface-overlay my-16 px-4 xl:px-0">
          <div
            class="mx-auto flex max-w-[1120px] flex-col items-center justify-center py-10"
          >
            <div class="py-12">
              <p-image
                [src]="
                  isDark
                    ? sdtkWebUrl + '/assets/icons/laptop-dark.svg'
                    : sdtkWebUrl + '/assets/icons/laptop-light.svg'
                "
              ></p-image>
            </div>
            <h3 class="h-level-3 highlight-text mb-3 text-center">Features</h3>
            <h1 class="h-level-1 highlight-text text-center">
              Powerful tools that feels like it’s from the future
            </h1>
            <p class="mb-8 text-center">
              Powerful, self-serve product and growth analytics to help you
              gether and engage more community members. Trusted by over 4,000
              communities.
            </p>
            <div class="mb-6 grid grid-cols-3 gap-x-16 gap-y-10">
              <div class="col-span-3 md:col-span-1">
                <div
                  class="flex w-auto flex-col items-center gap-4 md:w-[293px]"
                >
                  <img
                    [src]="sdtkWebUrl + '/assets/icons/overview.svg'"
                    alt=""
                    width="57"
                  />
                  <span class="h-level-4">Dashboard</span>
                  <p class="text-center">
                    Dashboard will help you monitor your growth and engagement,
                    and make data-driven decisions.
                  </p>
                </div>
              </div>
              <div class="col-span-3 md:col-span-1">
                <div
                  class="flex w-auto flex-col items-center gap-4 md:w-[293px]"
                >
                  <img
                    [src]="sdtkWebUrl + '/assets/icons/news-feed.svg'"
                    alt=""
                    width="57"
                  />
                  <span class="h-level-4">News Feed</span>
                  <p class="text-center">
                    News feed is a great way to keep your community engaged and
                    up to date with the latest news and updates.
                  </p>
                </div>
              </div>
              <div class="col-span-3 md:col-span-1">
                <div
                  class="flex w-auto flex-col items-center gap-4 md:w-[293px]"
                >
                  <img
                    [src]="sdtkWebUrl + '/assets/icons/connection.svg'"
                    alt=""
                    width="57"
                  />
                  <span class="h-level-4">Connections</span>
                  <p class="text-center">
                    Follow other members, create groups, and connect with other
                    like minded people.
                  </p>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-3 gap-x-16 gap-y-10">
              <div class="col-span-3 md:col-span-1">
                <div
                  class="flex w-auto flex-col items-center gap-4 md:w-[293px]"
                >
                  <img
                    [src]="sdtkWebUrl + '/assets/icons/dashboard.svg'"
                    alt=""
                    width="57"
                  />
                  <span class="h-level-4">Huddles</span>
                  <p class="text-center">
                    Create huddles to hike interesting trails, and meet new
                    people in your community.
                  </p>
                </div>
              </div>
              <div class="col-span-3 md:col-span-1">
                <div
                  class="flex w-auto flex-col items-center gap-4 md:w-[293px]"
                >
                  <img
                    [src]="sdtkWebUrl + '/assets/icons/carpool.svg'"
                    alt=""
                    width="57"
                  />
                  <span class="h-level-4">Carpool</span>
                  <p class="text-center">
                    Carpool is a great way to save money and reduce your carbon
                    footprint by sharing rides with others.
                  </p>
                </div>
              </div>
              <div class="col-span-3 md:col-span-1">
                <div
                  class="flex w-auto flex-col items-center gap-4 md:w-[293px]"
                >
                  <img
                    [src]="sdtkWebUrl + '/assets/icons/huddles.svg'"
                    alt=""
                    width="57"
                  />
                  <span class="h-level-4">Communities</span>
                  <p class="text-center">
                    Join communities that interest you, and meet new people who
                    share your interests.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="my-16 px-4 xl:px-0">
          <h1 class="h-level-1 highlight-text pb-6 text-center">Reviews</h1>
          <div
            class="shrink-1 flex grow-0 flex-col items-center justify-center"
          >
            <p-avatar
              [image]="sdtkWebUrl + '/assets/icons/group-2.png'"
              size="xlarge"
              shape="circle"
            ></p-avatar>
            <span class="highlight-text">Amélie Laurent</span>
            <span class="highlight-text mb-3">Community Builder</span>
            <p class="text-center">
              We’ve been using Mission Peak to build our community with new
              hikes and offering everyday, and can’t imagine working without it.
            </p>
          </div>
        </section>
        <section class="surface-overlay my-16 px-4 xl:px-0">
          <div class="mx-auto flex max-w-[1120px] flex-col py-8">
            <h1 class="h-level-1 highlight-text pb-3 text-center">
              Frequently asked questions
            </h1>
            <p class="pb-8 text-center">
              Everything you need to know about the product and billing.
            </p>
            <p-panel styleClass="faq" [toggleable]="true">
              <ng-template pTemplate="header">
                <span class="highlight-text font-semibold"
                  >Is there a free version available?</span
                >
              </ng-template>
              <p>
                Yes, for individuals and small communities, we offer a free plan
                that includes all the basic features you need to get started.
              </p>
            </p-panel>
            <p-divider styleClass="my-0"></p-divider>
            <p-panel styleClass="faq" [toggleable]="true" [collapsed]="true">
              <ng-template pTemplate="header">
                <span class="highlight-text font-semibold"
                  >Can I upgrade my plan later?</span
                >
              </ng-template>
              <p>
                Yes, you can upgrade your plan at any time. You can also
                downgrade your plan if you need to.
              </p>
            </p-panel>
            <p-divider styleClass="my-0"></p-divider>
            <p-panel styleClass="faq" [toggleable]="true" [collapsed]="true">
              <ng-template pTemplate="header">
                <span class="highlight-text font-semibold"
                  >What is your cancellation policy?</span
                >
              </ng-template>
              <p>
                You can cancel your subscription at any time. Once you cancel,
                you will not be charged again, but you are responsible for any
                charges already incurred.
              </p>
            </p-panel>
            <p-divider styleClass="my-0"></p-divider>
            <p-panel styleClass="faq" [toggleable]="true" [collapsed]="true">
              <ng-template pTemplate="header">
                <span class="highlight-text font-semibold"
                  >Can other info be added to an invoice?</span
                >
              </ng-template>
              <p>
                Yes, you can add a variety of information to your invoices. You
                can add your logo, company name, address, phone number, and
                email address. You can also add a due date, invoice number, and
                payment terms.
              </p>
            </p-panel>
            <p-divider styleClass="my-0"></p-divider>
            <p-panel styleClass="faq" [toggleable]="true" [collapsed]="true">
              <ng-template pTemplate="header">
                <span class="highlight-text font-semibold"
                  >How does billing work?</span
                >
              </ng-template>
              <p>
                You can choose to pay monthly or annually. If you choose to pay
                annually, you will receive a discount. You can cancel your
                subscription at any time.
              </p>
            </p-panel>
          </div>
        </section>
        <section class="my-16 px-4 xl:px-0">
          <div
            class="shrink-1 flex grow-0 flex-col items-center justify-center"
          >
            <p-avatar
              [image]="sdtkWebUrl + '/assets/icons/group-3.png'"
              size="xlarge"
              shape="circle"
            ></p-avatar>
            <span class="highlight-text mb-3">Still have questions?</span>
            <p class="mb-8 text-center">
              We’re here to help. Get in touch and we’ll get back to you as soon
              as we can.
            </p>
            <a
              href="mailto:support@siddhatek.com"
              target="_blank"
              class="mp-button mp-button-filled rounded px-4 py-3"
            >
              Get in touch
            </a>
          </div>
        </section>
        <section class="surface-overlay mt-16 px-4 xl:px-0">
          <div class="flex flex-col items-center justify-center py-10">
            <h3 class="h-level-3 highlight-text mb-3">Inspirations</h3>
            <h1 class="h-level-1 highlight-text">Stories and Articles</h1>
            <p class="mb-8 mt-2">
              Read, wirte and share your stories with the world. Inspire others
            </p>
            <div class="grid max-w-[1120px] grid-cols-6 gap-x-8 gap-y-5">
              <div class="col-span-6 md:col-span-2">
                <div class="flex flex-col gap-1">
                  <img
                    class="h-[265px] rounded-[30px] object-cover"
                    [src]="sdtkWebUrl + '/assets/icons/blog-1.png'"
                    alt=""
                  />
                  <span class="primary">Adventure</span>
                  <div class="flex justify-between">
                    <span class="h-level-3"
                      >First sight of the sunrise in the USA</span
                    >
                    <img
                      [src]="'/assets/icons/_blank.svg'"
                      class="cursor-pointer"
                      alt="redirect"
                    />
                  </div>
                  <span class="sub-title gray-500 mb-2"
                    >The first sunrise in the continental United States is
                    usually seen from the summit of Cadillac Mountain in Acadia
                    National Park, Maine...</span
                  >
                  <div class="flex gap-3">
                    <p-avatar
                      [image]="sdtkWebUrl + '/assets/icons/group-1.png'"
                      size="large"
                      shape="circle"
                      class="mb-3"
                    ></p-avatar>
                    <div class="flex flex-col">
                      <span class="highlight-text">Andrew Harrison</span>
                      <span class="highlight-text mb-3"
                        >Hobbiest hiker and blogger</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-6 md:col-span-2">
                <div class="flex flex-col gap-1">
                  <img
                    class="h-[265px] rounded-[30px] object-cover"
                    [src]="sdtkWebUrl + '/assets/icons/blog-2.png'"
                    alt=""
                  />
                  <span class="primary">Inspirational Stories</span>
                  <div class="flex justify-between">
                    <span class="h-level-3"
                      >Day hikes in Glacier National Park</span
                    >
                    <img
                      [src]="sdtkWebUrl + '/assets/icons/_blank.svg'"
                      class="cursor-pointer"
                      alt="redirect"
                    />
                  </div>
                  <span class="sub-title gray-500 mb-2"
                    >Our journey to hike one of the best hiking experience and
                    tips to stay safe and sound.</span
                  >
                  <div class="flex gap-3">
                    <p-avatar
                      [image]="sdtkWebUrl + '/assets/icons/group-2.png'"
                      size="large"
                      shape="circle"
                      class="mb-3"
                    ></p-avatar>
                    <div class="flex flex-col">
                      <span class="highlight-text">Amélie Laurent</span>
                      <span class="highlight-text mb-3">Community Builder</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-6 md:col-span-2">
                <div class="flex flex-col gap-1">
                  <img
                    class="h-[265px] rounded-[30px]"
                    [src]="sdtkWebUrl + '/assets/icons/blog-3.png'"
                    alt=""
                  />
                  <span class="primary">Trails and Routes</span>
                  <div class="flex justify-between">
                    <span class="h-level-3">Clouds Rest Trail</span>
                    <img
                      [src]="sdtkWebUrl + '/assets/icons/_blank.svg'"
                      class="cursor-pointer"
                      alt="redirect"
                    />
                  </div>
                  <span class="sub-title gray-500 mb-2"
                    >Check out this 13.5-mile out-and-back trail near Yosemite
                    Valley, California.</span
                  >
                  <div class="flex gap-3">
                    <p-avatar
                      [image]="sdtkWebUrl + '/assets/icons/group-3.png'"
                      size="large"
                      shape="circle"
                      class="mb-3"
                    ></p-avatar>
                    <div class="flex flex-col">
                      <span class="highlight-text">Ashish Kumar</span>
                      <span class="highlight-text mb-3"
                        >Founder, Mission Peak</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</ng-template>
