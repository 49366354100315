<div class="container mb-4 max-w-full">
  <div class="flex flex-col items-center">
    <img
      class="h-[480px]"
      alt="Error 404 - page not found."
      src="./../../../assets/images/404.svg"
    />
    <h1 class="h-level-1 mb-4">Page not found.</h1>
    <p class="text-center">The page you are looking for doesn't exist.</p>
    <p class="mb-4 text-center">Here are some helful links.</p>
    <div class="mb-4 flex gap-3">
      <a
        (click)="goBack()"
        class="mp-button mp-button-outlined cursor-pointer rounded px-4 py-2"
      >
        <span class="material-icons inline-block align-middle">arrow_back</span>
        Go back
      </a>
      <a
        [routerLink]="[DEFAULT_HOME]"
        class="mp-button mp-button-filled cursor-pointer rounded px-4 py-2"
        >Take me home</a
      >
    </div>
  </div>
</div>
