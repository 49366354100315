import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuItem, MessageService } from 'primeng/api';
import { TieredMenu } from 'primeng/tieredmenu';
import { Observable, Subject, tap } from 'rxjs';
import { CommunityService } from 'src/app/main/services/community.service';
import { PlatformService } from 'src/app/main/services/platform.service';
import { PostService } from 'src/app/main/services/post.services';
import { AppState } from 'src/app/main/store/model/state.model';
import { Community, UserDetails } from 'src/app/main/types/main.types';
import { visibilityMap } from 'src/app/main/utilities';
import { communityMoreOptions } from 'src/app/main/utilities/overlayHelper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-community-public-view',
  templateUrl: './community-public-view.component.html',
})
export class CommunityPublicViewComponent implements OnInit {
  isLoading = true;

  tabs: MenuItem[] = [];

  itemFor: string;

  activeTab: MenuItem | undefined;

  communityId: string | null;

  community: Community;

  communityMoreOptions = communityMoreOptions;

  visible = false;

  userInfo: UserDetails;

  profileUrl = '';

  coverPhotoUrl = '';

  logoUrl = '';

  isShare = false;

  isAcceptedOrDecline = false;

  selectedUser: number[] = [];

  visibilityMap = visibilityMap;

  userDetail$: Observable<UserDetails>;

  userPickerEvent: Subject<any> = new Subject<any>();

  shareLinkEvent: Subject<boolean> = new Subject<boolean>();

  @ViewChild('menu', { static: false }) menuRef: TieredMenu;

  constructor(
    private route: ActivatedRoute,
    private communityService: CommunityService,
    private messageService: MessageService,
    private store: Store<AppState>,
    private router: Router,
    private postService: PostService,
    private platformService: PlatformService,
  ) {
    this.tabs = [
      { label: 'Overview' },
      { label: 'Huddles' },
      { label: 'Messages' },
      { label: 'Members' },
      { label: 'Settings' },
    ];
  }

  ngOnInit(): void {
    this.communityId = this.route.snapshot.paramMap.get('id');
    this.getUserInfo();
    if (this.platformService.getIsBrowser()) this.getCommunities();
  }

  getUserInfo() {
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.userInfo = user;
          this.profileUrl =
            environment.apiUrl + '/api/user/' + user.id + '/pic';
        }),
      );
    this.userDetail$.subscribe();
  }

  getCommunities() {
    this.communityService.getCommunity(this.communityId).subscribe({
      next: (data: any) => {
        this.community = data;
        this.coverPhotoUrl =
          environment.apiUrl + '/api/communities/' + data.id + '/cover';
        this.logoUrl =
          environment.apiUrl + '/api/communities/' + data.id + '/logo';
        this.isLoading = false;
        setTimeout(() => {
          const _tab = this.route.snapshot.queryParamMap.get('tab');
          if (_tab) {
            this.activeTab = this.tabs.find((t) => t.label === _tab);
          } else {
            !this.activeTab?.label && (this.activeTab = this.tabs[0]);
          }
        }, 800);
      },
      error: (err) => {
        console.log(err);
        this.messageService.add({
          severity: 'error',
          summary: 'Community',
          detail: err.error?.message || err.message,
        });
        this.isLoading = false;
      },
    });
  }

  onTieredMenuItemClick(id: string) {
    if (id === 'copy_link') {
      this.shareLinkEvent.next(!0);
    } else if (id === 'leave') {
      this.communityService.leave(this.communityId as string).subscribe({
        next: (data: any) => {
          console.log(data);
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Leave',
            detail: data.message,
          });
        },
        error: (err) => this.handleCatch(err, 'Leave'),
      });
    } else if (id === 'create_huddle') {
      this.router.navigate(['/huddle', 'create'], {
        queryParams: { communityId: this.communityId },
      });
    } else if (id === 'decline_invite') {
      this.declineInvite();
    }
  }

  selfJoinAndCancelRequest(isCancel = false) {
    const method = isCancel ? 'selfCanelJoin' : 'selfRequestJoin';
    this.communityService[method](this.communityId as string).subscribe({
      next: (data: any) => {
        this.messageService.add({
          severity: data.error ? 'error' : 'success',
          summary: 'Self Join',
          detail: data.message,
        });
        this.community.membership.joinRequested =
          !this.community.membership.joinRequested;
      },
      error: (err) => {
        this.handleCatch(err, 'Self Join');
      },
    });
  }

  onInviteClick(isShare = false) {
    this.isShare = isShare;
    this.visible = true;
    this.userPickerEvent.next([]);
    this.userPickerEvent.next('onLoad');
    this.userPickerEvent.next(true);
  }

  onInvite(e: any) {
    this.communityService.inviteUser(this.communityId as string, e).subscribe({
      next: (data: any) => {
        this.messageService.add({
          severity: data.error ? 'error' : 'success',
          summary: 'Invite User',
          detail: data.message,
        });

        if (!data.error) {
          this.userPickerEvent.next({ state: 'Invited', id: e });
          this.userPickerEvent.next('loaderStop');
        }
      },
      error: (err) => this.handleCatch(err, 'Invite User'),
    });
  }

  handleCatch(err: any, summary: string) {
    const { errors = null } = err?.error || {};
    this.messageService.add({
      severity: 'error',
      summary,
      detail: errors ? errors[0] : 'Something went wrong',
    });
    this.isLoading = false;
    console.log('Error in selfJoinRequest: ', err);
  }

  onActiveItemChange(e: any) {
    this.activeTab = e;
  }

  onMenuToggle(e: any, item: string) {
    this.itemFor = item;
    if (!this.community.membership.member) {
      this.communityMoreOptions = this.communityMoreOptions.filter(
        (c) => c.label !== 'leave',
      );
    }
    this.menuRef.toggle(e);
  }

  onList(e: UserDetails) {
    this.selectedUser.push(e.id);
  }

  onRemove(e: number) {
    this.selectedUser = this.selectedUser.filter((s) => s !== e);
  }

  onClickInvite(event: any) {
    this.postService
      .shareWithUsers({
        objectId: this.communityId as string,
        objectType: 'COMMUNITY',
        userIds: this.selectedUser,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Share to Friend',
            detail: data.message,
          });
          this.visible = false;
        },
        error: (err: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Share to Friend',
            detail: err.error.message,
          });
        },
      });
  }

  declineInvite() {
    this.communityService.declineInvite('' + this.community.id).subscribe({
      next: (data: any) => {
        this.messageService.add({
          severity: data.error ? 'error' : 'success',
          summary: 'Decline',
          detail: data.message,
        });
        this.isLoading = false;
        this.isAcceptedOrDecline = true;
      },
      error: (err) => {
        this.handleCatch(err, 'Decline');
        this.isAcceptedOrDecline = false;
      },
    });
  }

  accept(e: any) {
    this.communityService.acceptInvite('' + this.community.id).subscribe({
      next: (data: any) => {
        this.messageService.add({
          severity: data.error ? 'error' : 'success',
          summary: 'Accept',
          detail: data.message,
        });
        this.isAcceptedOrDecline = true;
      },
      error: (err) => {
        this.handleCatch(err, 'Accept');
        this.isAcceptedOrDecline = false;
      },
    });
  }
}
