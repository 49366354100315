<div *ngIf="!isLoading" class="mp-main overflow-hidden">
  <mat-sidenav-container [hasBackdrop]="isSideNavMode === 'over'">
    <mat-sidenav
      [mode]="isSideNavMode"
      [opened]="isSideNavOpened"
      (closedStart)="openSideNav({ isSideNavOpen: false })"
      class="w-10/12 md:w-auto"
    >
      <div class="surface-overlay h-full">
        <app-sidenav2 (subMenuClick)="onSubMenu($event)"></app-sidenav2>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="sticky top-0 z-100">
        <app-appbar
          [isSidebarOpen]="isSideNavOpened"
          (sideNavOpenEvent)="openSideNav($event)"
        ></app-appbar>
      </div>
      <div class="mp-main">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<p-dialog
  [header]="terms?.heading"
  [(visible)]="termsVisible"
  [modal]="true"
  [style]="{ width: '30vw' }"
  [closable]="false"
  [breakpoints]="{ '960px': '90vw', '360px': '95vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <div class="mb-2 flex flex-col" *ngFor="let term of terms?.content">
    <h2 class="h-level-2 font-semibold">{{ term.title }}</h2>
    <p>{{ term.description }}</p>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex justify-end">
      <button
        type="button"
        pButton
        (click)="onTermRead()"
        class="mp-button mp-button-outlined h-12 w-full justify-center rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
      >
        Okay
      </button>
    </div>
  </ng-template>
</p-dialog>
<app-loading *ngIf="isLoading"></app-loading>
