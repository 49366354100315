<header class="surface-overlay border-b border-b-black">
  <nav class="flex h-20 items-center p-4">
    <button class="block p-2 lg:hidden" (click)="sidebarVisible = true">
      <span
        class="material-icons h-level-1 highlight-text inline-block align-middle"
        >menu</span
      >
    </button>
    <p-sidebar
      [(visible)]="sidebarVisible"
      (onShow)="onSidebarShowAndHide(true)"
      (onHide)="onSidebarShowAndHide(false)"
    >
      <ng-template pTemplate="header">
        <div class="flex items-center gap-3 p-3">
          <img
            [src]="
              !isDark
                ? '/assets/icons/logo-dark-512w.svg'
                : '/assets/icons/logo-light-512w.svg'
            "
            alt="logo"
            class="h-12"
          />
          <img
            [src]="
              isDark
                ? '/assets/icons/logo-text-light-beta.svg'
                : '/assets/icons/logo-text-dark-beta.svg'
            "
            alt="logo"
            class="h-10"
          />
        </div>
      </ng-template>
      <div class="flex self-stretch">
        <div class="flex flex-1 flex-col items-start">
          <div class="mb-2 w-full">
            <ul role="list" class="flex flex-col gap-3">
              <li
                [ngClass]="{ selected: route.url.includes('start') }"
                class="menu-item flex cursor-pointer justify-between rounded-md p-4"
              >
                <a (click)="redirectTo(['/start'])">Home</a>
              </li>
              <li
                class="menu-item cursor-pointer rounded-md p-4"
                routerLinkActive="selected"
              >
                <a (click)="redirectTo(['/explore', 'inspiration'])"
                  >Inspiration</a
                >
              </li>
              <li
                class="menu-item cursor-pointer rounded-md p-4"
                routerLinkActive="selected"
              >
                <a [href]="sdtkWebUrl + '/mobile'" target="_blank">Mobile</a>
              </li>
              <p-divider styleClass="m-0"></p-divider>
              <li
                class="menu-item flex cursor-pointer items-center rounded-md p-4"
              >
                <span class="mr-4">Switch Theme</span>
                <p-dropdown
                  [options]="publicMenuOptions"
                  [(ngModel)]="selectedTheme"
                  optionLabel="label"
                  (onChange)="onTieredMenuClick(selectedTheme.action)"
                >
                  <ng-template pTemplate="selectedItem">
                    <div
                      class="align-items-center flex gap-2"
                      *ngIf="selectedTheme"
                    >
                      <span class="material-icons-outlined">{{
                        selectedTheme.icon
                      }}</span>
                      <div>{{ selectedTheme.label }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-menu pTemplate="item">
                    <div
                      *ngIf="menu.action !== 'logout'"
                      class="align-items-center flex gap-2"
                    >
                      <span class="material-icons-outlined">{{
                        menu.icon
                      }}</span>
                      <div>{{ menu.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <div class="flex gap-3">
          <a
            (click)="signIn()"
            target="_blank"
            class="mp-button mp-button-filled rounded px-4 py-2"
          >
            Login
          </a>
          <a
            (click)="signUp()"
            target="_blank"
            class="mp-button mp-button-outlined rounded px-4 py-2"
          >
            Sign up
          </a>
        </div>
      </ng-template>
    </p-sidebar>
    <div class="flex flex-1 self-stretch md:gap-x-6">
      <div class="flex flex-1 items-center gap-3">
        <img
          [src]="
            !isDark
              ? '/assets/icons/logo-dark-512w.svg'
              : '/assets/icons/logo-light-512w.svg'
          "
          alt="logo"
          class="hidden h-12 md:block"
        />
        <img
          [src]="
            !isDark
              ? '/assets/icons/logo-text-dark-beta.svg'
              : '/assets/icons/logo-text-light-beta.svg'
          "
          alt="logo"
          class="h-10 md:h-12"
        />
        <div class="ml-8 hidden lg:block">
          <ul role="list" class="flex gap-3">
            <li
              class="menu-item cursor-pointer rounded-md p-4"
              [ngClass]="{ selected: route.url.includes('start') }"
            >
              <a (click)="redirectTo(['/start'])">Home</a>
            </li>
            <li
              class="menu-item cursor-pointer rounded-md p-4"
              [ngClass]="{ selected: route.url.includes('inspiration') }"
            >
              <a (click)="redirectTo(['/explore', 'inspiration'])"
                >Inspiration</a
              >
            </li>
            <li
              class="menu-item cursor-pointer rounded-md p-4"
              [ngClass]="{ selected: route.url.includes('mobile') }"
            >
              <a [href]="sdtkWebUrl + '/mobile'" target="_blank">Mobile</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex items-center sm:gap-x-2 md:gap-x-4">
        <button
          class="hidden md:inline-flex"
          pButton
          *ngIf="!authenticated"
          (click)="onToggleOverlayMenu($event, 'theme')"
        >
          <span class="material-icons highlight-text">{{
            isDark ? "dark_mode" : "wb_sunny"
          }}</span>
        </button>
        <div class="flex gap-3" *ngIf="!authenticated">
          <a
            (click)="signIn()"
            target="_blank"
            class="mp-button mp-button-filled cursor-pointer rounded px-4 py-2"
          >
            Log In
          </a>
          <a
            (click)="signUp()"
            target="_blank"
            class="mp-button mp-button-outlined hidden cursor-pointer rounded px-4 py-2 md:inline-flex"
          >
            Sign up
          </a>
        </div>
        <div class="relative flex items-center gap-2" *ngIf="authenticated">
          <button
            class="mp-button mp-button-outlined flex h-[30px] items-center gap-0 rounded py-1 pl-0 pr-[0.15rem] sm:h-[35px] sm:gap-1 sm:pl-[0.5rem] sm:pr-[0.4rem]"
            (click)="redirectTo([DEFAULT_HOME])"
            title="avatar"
          >
            <span class="hidden sm:block">Go to Dashboard</span>
            <span class="material-icons block sm:hidden">arrow_forward</span>
            <span class="material-icons">space_dashboard</span>
          </button>
          <button
            class="flex items-center p-1.5"
            (click)="menuRef.toggle($event)"
            title="avatar"
          >
            <img
              class="primary-border h-9 w-9 rounded-full"
              [src]="picPhotoUrl"
              alt="default"
            />
          </button>
        </div>
      </div>
    </div>
  </nav>
</header>

<app-user-tiered-menu
  *ngIf="authenticated"
  (userMenuRef)="getMenuRef($event)"
></app-user-tiered-menu>

<p-overlayPanel #tieredMenu styleClass="public-tiered-menu">
  <div
    class="duration relative flex w-40 grow flex-col gap-y-4 overflow-hidden transition-[height]"
  >
    <div
      class="main-menu w-full opacity-100 transition-[opacity] transition-[transform] duration-300"
    >
      <div class="">
        <ul role="list" class="flex flex-col">
          <ng-container *ngFor="let menu of publicMenuOptions">
            <li
              *ngIf="menu.item === itemFor"
              class="menu-item cursor-pointer px-3 py-2"
              (click)="onTieredMenuClick(menu.action)"
              [ngClass]="{
                'selected selected-border':
                  appliedTheme === menu.action && itemFor === 'theme',
              }"
            >
              <div class="flex gap-3">
                <span
                  class="material-icons-outlined"
                  [ngClass]="{
                    'highlight-text':
                      appliedTheme === menu.action && itemFor === 'theme',
                  }"
                  >{{ menu.icon }}</span
                >
                <a class="">{{ menu.label }}</a>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</p-overlayPanel>

<div
  *ngIf="showMask"
  (click)="onMaskClick()"
  class="public-sidebar-mask fixed bottom-0 right-0 top-0 h-full"
></div>
