import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
})
export class HealthComponent implements OnInit {
  healthResultStr = '';

  ngOnInit() {
    const healthResult = {
      health: 'OK',
    };

    this.healthResultStr = JSON.stringify(healthResult, null, 2);

    /*
    // https://stackoverflow.com/questions/59267133/return-plain-json-when-calling-specific-router-url
    const thefile = new Blob([JSON.stringify(healthResult)], {
      type: 'application/json',
    });

    const url = window.URL.createObjectURL(thefile);
    window.location.href = url;
    */
  }
}
