import { DOCUMENT, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
} from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import termsJson from 'src/configs/terms.json';
import { NotificationsService } from './services/notifications.service';
import { SseService } from './services/sse.service';
import { ThemeService } from './services/theme.service';
import { NotificationType } from './store/actions/notification.action';
import { AppState } from './store/model/state.model';
import { NotificationSummary, UserDetails } from './types/main.types';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit, AfterViewInit {
  isLoading = false;

  isMainAllowed = false;
  isResized = false;
  termsVisible = false;
  terms: any;
  isSmallScreen = false;
  isSideNavOpened = true;
  isSideNavMode: MatDrawerMode = 'side';
  userDetail$: Observable<UserDetails>;

  tieredMenuClicked: any;
  title = 'Dashboard';
  userDetails: UserDetails;
  shouldLoad = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private store: Store<AppState>,
    private themeService: ThemeService,
    private cdRef: ChangeDetectorRef,
    private sseService: SseService,
    private location: Location,
    private notificationsService: NotificationsService,
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.onTermShow(event.url);
      }
    });
  }

  ngOnInit(): void {
    this.subscribeStore();
  }

  ngAfterViewInit(): void {
    this.configureSideNav();
    this.cdRef.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.configureSideNav();
    console.log('Resize');
  }

  configureSideNav() {
    this.isSmallScreen = window.innerWidth < 1024 ? true : false;
    if (!this.isSmallScreen) {
      this.isSideNavMode = 'side';
      this.isSideNavOpened = true;
    } else {
      this.isSideNavMode = 'over';
      this.isSideNavOpened = false;
    }
  }

  openSideNav(eventData: { isSideNavOpen: boolean }) {
    this.isSideNavOpened = eventData.isSideNavOpen;
  }

  onSubMenu(e: boolean) {
    this.isSideNavMode === 'over' && (this.isSideNavOpened = false);
  }

  getUserInfo(data: UserDetails) {
    console.log('Got user information from the server.');
    this.isLoading = data.loading as boolean;
    if (!data.loading) {
      this.userDetails = data;
      this.shouldLoad = false;
      if (data['emailVerified'] !== true) {
        this.router.navigateByUrl('/verify/email');
      } else if (data['onboarded'] !== true) {
        this.router.navigateByUrl('/onboard');
      } else {
        this.isMainAllowed = true;
        this.getNotificationsSummaryInitial();

        // Subscribe to SSE for Notifications Summary
        this.getNotificationsSummary();
      }
      const media = window.matchMedia('(prefers-color-scheme: light)');
      this.toggleTheme(data.theme, media);
      media.addEventListener('change', (e: MediaQueryListEvent) => {
        console.log(data.theme);
        this.toggleTheme(data.theme, e);
      });
    }
  }

  subscribeStore() {
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((data) => {
          this.getUserInfo(data);
        }),
      );
    this.userDetail$.subscribe();
  }

  toggleTheme(theme: string, e?: MediaQueryList | MediaQueryListEvent) {
    if (theme === 'SYSTEM') {
      if (e?.matches) {
        this.themeService.switchTheme('lara-light-teal');
      } else if (!e?.matches) {
        this.themeService.switchTheme('lara-dark-teal');
      }
    }
  }

  getNotificationsSummary() {
    this.sseService
      .createFetchEventSource()
      .subscribe((summary: NotificationSummary) => {
        this.store.dispatch({
          type: NotificationType.NOTIFICATION_SUMMARY,
          payload: summary as NotificationSummary,
        });
      });
  }

  getNotificationsSummaryInitial() {
    this.notificationsService.getNotificationSummary().subscribe({
      next: (summary: any) => {
        summary.loading = false;
        this.store.dispatch({
          type: NotificationType.NOTIFICATION_SUMMARY,
          payload: summary as NotificationSummary,
        });
      },
      error: (err: Error) => {
        console.error(
          'Request to get notifications count from the server errored out.',
        );
        console.error(err.message);
      },
    });
  }

  onTermShow(path: string) {
    this.terms = termsJson.find((t) => path.includes(t.path));
    const isSeen = localStorage.getItem('term_' + this.terms?.heading);
    if (!isSeen && this.terms) {
      this.termsVisible = true;
    }
  }

  onTermRead() {
    this.termsVisible = false;
    localStorage.setItem(`term_${this.terms.heading}`, 'true');
  }
}
