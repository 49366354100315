import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicRoutingModule } from './public-routing.module';
import { SharedModule } from '../shared/shared.module';

import { PublicComponent } from './public.component';
import { ErrorComponent } from './error/error.component';
import { NotFoundComponent } from './notfound/notfound.component';

import { CardModule } from 'primeng/card';
import { ImageModule } from 'primeng/image';
import { TabMenuModule } from 'primeng/tabmenu';
import { ButtonModule } from 'primeng/button';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AvatarModule } from 'primeng/avatar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ChipModule } from 'primeng/chip';
import { TooltipModule } from 'primeng/tooltip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { DividerModule } from 'primeng/divider';
import { SidebarModule } from 'primeng/sidebar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { HealthComponent } from './health/health.component';
import { StartComponent } from './start/start.component';
import { AppbarPublicComponent } from './appbar-public/appbar-public.component';
import { InspirationPublicComponent } from './inspiration-public/inspiration-public.component';
import { InspirationPublicViewComponent } from './inspiration-public-view/inspiration-public-view.component';
import { FooterComponent } from './footer/footer.component';
import { MobileComponent } from './mobile/mobile.component';
import { CarouselModule } from 'primeng/carousel';
import { InspirationModule } from '../main/views/inspiration/inspiration.module';
import { MenuModule } from 'primeng/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CheckboxModule } from 'primeng/checkbox';
import { HuddleViewPublicComponent } from './huddle-public-view/huddle-public-view.component';
import { CommunityPublicViewComponent } from './community-public-view/community-public-view.component';
import { JoinComponent } from './join/join.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputNumberModule } from 'primeng/inputnumber';

@NgModule({
  declarations: [
    PublicComponent,
    ErrorComponent,
    NotFoundComponent,
    SignInComponent,
    SignUpComponent,
    HealthComponent,
    StartComponent,
    FooterComponent,
    AppbarPublicComponent,
    MobileComponent,
    InspirationPublicComponent,
    InspirationPublicViewComponent,
    HuddleViewPublicComponent,
    CommunityPublicViewComponent,
    JoinComponent,
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    SharedModule,
    CardModule,
    ButtonModule,
    TabMenuModule,
    ImageModule,
    AvatarGroupModule,
    AvatarModule,
    ProgressSpinnerModule,
    TieredMenuModule,
    ChipModule,
    TooltipModule,
    RadioButtonModule,
    AccordionModule,
    DividerModule,
    SidebarModule,
    OverlayPanelModule,
    PanelModule,
    DropdownModule,
    CarouselModule,
    MenuModule,
    InspirationModule,
    FontAwesomeModule,
    CheckboxModule,
    SelectButtonModule,
    InputNumberModule,
  ],
})
export class PublicModule {}
