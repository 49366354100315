import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { ProfilePublicComponent } from './profile/profile-public.component';
import { UsersComponent } from './users.component';

import { CardModule } from 'primeng/card';
import { ImageModule } from 'primeng/image';
import { TabMenuModule } from 'primeng/tabmenu';
import { ButtonModule } from 'primeng/button';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AvatarModule } from 'primeng/avatar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ChipModule } from 'primeng/chip';
import { TooltipModule } from 'primeng/tooltip';
import { UsersRoutingModule } from './users-routing.module';
import { NavComponent } from './nav/nav.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { VerifyEmailComponent } from './verify-email/verify-email.component';

@NgModule({
  declarations: [
    UsersComponent,
    ProfilePublicComponent,
    NavComponent,
    VerifyEmailComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    UsersRoutingModule,
    CardModule,
    ButtonModule,
    TabMenuModule,
    ImageModule,
    AvatarGroupModule,
    AvatarModule,
    ProgressSpinnerModule,
    TieredMenuModule,
    ChipModule,
    TooltipModule,
    InfiniteScrollModule,
  ],
})
export class UsersModule {}
