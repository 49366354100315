<div class="container mb-4 max-w-full">
  <div class="flex h-[600px] items-center justify-center">
    <div class="flex flex-col items-center">
      <h1 class="h-level-1 highlight-text mb-3">Error!&nbsp;🙁</h1>
      <p class="mb-6 px-4 text-center">
        Oops! Something went wrong. Please try again later.
      </p>
      <div class="mb-4 flex gap-3">
        <a
          (click)="goBack()"
          class="mp-button mp-button-outlined cursor-pointer rounded px-4 py-2"
        >
          <span class="material-icons inline-block align-middle"
            >arrow_back</span
          >
          Go back
        </a>
        <a
          [routerLink]="['/']"
          class="mp-button mp-button-filled cursor-pointer rounded px-4 py-2"
          >Take me home</a
        >
      </div>
    </div>
  </div>
</div>
