import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-inspiration-public-view',
  templateUrl: './inspiration-public-view.component.html',
})
export class InspirationPublicViewComponent implements OnInit {
  @Input() inspirationId: string | null;

  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.inspirationId = this.route.snapshot.paramMap.get('id');
  }
}
