import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
})
export class SignUpComponent {
  constructor(public authService: AuthService) {
    console.log(authService);
    this.signUp();
  }

  signUp() {
    this.authService.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  }
}
