import { Component } from '@angular/core';
import { ThemeService } from 'src/app/main/services/theme.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
})
export class MobileComponent {
  sdtkWebUrl = environment.sdtkWebUrl;

  isDark = false;

  constructor(readonly themeService: ThemeService) {
    this.isDark = localStorage.getItem('isDark') === 'true';
    this.themeService.theme$.subscribe((data: boolean) => {
      this.isDark = data;
    });
  }
}
