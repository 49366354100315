import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  IconDefinition,
  faAngleDown,
  faCar,
  faStar,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import moment from 'moment';
import { MenuItem, MessageService } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { Observable, Subject } from 'rxjs';
import { PlatformService } from 'src/app/main/services/platform.service';
import {
  HuddlePublic,
  HuddleSettings,
  User,
  UserDetails,
} from 'src/app/main/types/main.types';
import {
  ActivityEnum,
  activityMap,
  calculateDuration,
  formatEventDate,
  initMap,
  paymentType,
  visibilityMap,
} from 'src/app/main/utilities';
import { AgeRangesFields } from 'src/app/main/utilities/formHelper';
import {
  goingOptions,
  huddleMoreOptions,
  requestedOptions,
} from 'src/app/main/utilities/overlayHelper';
import { PaymentMethodName } from 'src/app/main/utilities/paymentMethodHelper';
import { environment } from 'src/environments/environment';
import { PublicService } from '../public.service';

enum TripTypeEnum {
  R = 'ROUND',
  D = 'DEPART',
  RT = 'RETURN',
}

@Component({
  selector: 'app-huddle-public-view',
  templateUrl: './huddle-public-view.component.html',
})
export class HuddleViewPublicComponent implements OnInit {
  visible = false;
  carpoolVisible = false;
  activityMap = activityMap;
  showFullVenueNote = false;
  showFullMeetingNote = false;
  showFullParkingNote = false;
  coverPhotoUrl: string;
  submitted = false;
  carpoolFormGroup!: FormGroup;
  faUsers: IconDefinition = faUsers;
  faUser: IconDefinition = faUser;
  faCar: IconDefinition = faCar;
  faStar: IconDefinition = faStar;
  faAngleDown: IconDefinition = faAngleDown;
  tabs: MenuItem[] = [];
  activeTab: MenuItem | undefined;
  eventDate: number;
  eventLongDate: Date;
  eventLongDateEnd: Date | null;
  huddleView: HuddlePublic;
  isRoundOrDepart = false;
  isReturn = false;
  startDate: Date = new Date();
  huddleId: string | null;
  userId: string | null;
  startAddressSubject: Subject<any> = new Subject<any>();
  endAddressSubject: Subject<any> = new Subject<any>();
  moreOptions: any = huddleMoreOptions;
  goingOptions: any = goingOptions;
  requestedOptions = requestedOptions;
  profilePic: string;
  userDetail$: Observable<UserDetails>;
  userInfo: UserDetails;
  canCreateCarpool: boolean;
  isLoading = true;
  isPastHuddle = false;
  inviteVisible = false;
  activityEnum = ActivityEnum;
  privacyMap = visibilityMap;
  paymentTypeMap = paymentType;
  coHosts: User[];
  menuId: string;
  moment = moment;
  rsvpType = '';
  selectedRsvpType = '';
  selectedButtonGuests: any[];
  selectedGuest: any;
  adult = 0;
  teens = 0;
  kids = 0;
  toddlers = 0;
  seniors = 0;
  isCarpoolAllowed = false;
  settings: HuddleSettings;
  eventRsvpStartDateTime: Date | null;
  eventRsvpEndDateTime: Date | null;
  @ViewChild('rsvp', { static: false }) rsvpDialog: ConfirmDialog;
  isDialogVisible = false;

  constructor(
    private publicService: PublicService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private platformService: PlatformService,
  ) {
    this.selectedButtonGuests = [
      { label: 'Confirm', value: 'YES' },
      { label: 'Tentative', value: 'MAYBE' },
    ];
    this.selectedGuest = 'YES';
  }

  ngOnInit(): void {
    this.huddleId = this.activatedRoute.snapshot.paramMap.get('id');
    this.userId = this.activatedRoute.snapshot.queryParamMap.get('userId');
    this.getHuddlePublicly();
  }

  getHuddlePublicly() {
    this.publicService
      .getPublicHuddle({
        huddleId: this.huddleId as string,
        userId: this.userId as string,
      })
      .subscribe({
        next: (data: any) => {
          const {
            startDateTime,
            endDateTime,
            features,
            rsvpStartDateTime,
            rsvpEndDateTime,
          } = data;
          const startDate = new Date(startDateTime);
          this.huddleView = data;
          this.userInfo = data.user;
          this.settings = data.settings;
          this.rsvpType = this.huddleView.participation.rsvpType || '';
          this.coverPhotoUrl =
            environment.apiUrl + '/api/huddles/' + data.id + '/cover';
          this.eventDate = startDate.getDate();
          this.eventLongDate = startDate;
          this.eventLongDateEnd = endDateTime ? new Date(endDateTime) : null;
          this.eventRsvpStartDateTime = rsvpStartDateTime
            ? new Date(rsvpStartDateTime)
            : null;
          this.eventRsvpEndDateTime = rsvpEndDateTime
            ? new Date(rsvpEndDateTime)
            : null;
          this.isLoading = false;
          const { venue, parking, meetingPoint } = data;
          const addresses: { label: string; address: string | undefined }[] =
            [];
          venue &&
            addresses.push({ label: 'V', address: venue.formattedAddress });
          parking &&
            addresses.push({ label: 'P', address: parking.formattedAddress });
          meetingPoint &&
            addresses.push({
              label: 'M',
              address: meetingPoint.formattedAddress,
            });
          !venue &&
            !meetingPoint &&
            !parking &&
            addresses.push({ label: '', address: 'New York, NY, USA' });
          if (this.platformService.getIsBrowser())
            initMap(
              'gmap-about',
              addresses as { label: string; address: string }[],
            );
          this.coHosts = this.huddleView.coHosts.concat(data.host);
          this.isCarpoolAllowed = features.some(
            (u: any) => u.enabled && u.key === 'CARPOOLING',
          );

          if (data.settings.decisiveRsvp) {
            this.goingOptions = this.goingOptions.filter(
              (going: any) => going.label !== 'Tentative',
            );
          }
        },
        error: (err) => {
          this.isLoading = false;
          console.error('Request to get huddle errored out.' + err.message);
        },
      });
  }

  onStartAddressChange(e: any) {
    this.carpoolFormGroup.get('startAddress')?.patchValue(e);
    initMap('gmap-route').then((map) => {
      if (this.carpoolFormGroup.get('endAddress')?.value) {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        this.calculateAndDisplayRoute(directionsService, directionsRenderer);
      }
    });
  }

  onEndAddressChange(e: any) {
    this.carpoolFormGroup.get('endAddress')?.patchValue(e);
    initMap('gmap-route').then((map) => {
      if (this.carpoolFormGroup.get('startAddress')?.value) {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        this.calculateAndDisplayRoute(directionsService, directionsRenderer);
      }
    });
  }

  onTripTypeChange(e: any) {
    const { value } = e;
    const tripType = value.code;
    this.isRoundOrDepart =
      tripType === TripTypeEnum.D || tripType === TripTypeEnum.R;
    this.isReturn = tripType === TripTypeEnum.RT;
  }

  editCarpool(data: any) {
    this.canCreateCarpool = !data;
  }

  calculateAndDisplayRoute(
    directionsService: google.maps.DirectionsService,
    directionsRenderer: google.maps.DirectionsRenderer,
  ) {
    const waypts: google.maps.DirectionsWaypoint[] = [];
    const _startAddress: string =
      this.carpoolFormGroup.get('startAddress')?.value.formattedAddress;
    const _endAddress: string =
      this.carpoolFormGroup.get('endAddress')?.value.formattedAddress;
    directionsService
      .route({
        origin: _startAddress,
        destination: _endAddress,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
      });
  }

  renderAddress() {
    this.startAddressSubject.next({
      action: 'FORM',
      payload: {
        startAddress: this.carpoolFormGroup.get('startAddress')?.value,
      },
    });
    this.endAddressSubject.next({
      action: 'FORM',
      payload: {
        endAddress: this.carpoolFormGroup.get('endAddress')?.value,
      },
    });
    this.onEndAddressChange(this.carpoolFormGroup.get('endAddress')?.value);
  }

  get f() {
    return this.carpoolFormGroup.controls;
  }

  onClickCarpool() {
    this.activeTab = this.tabs?.at(1);
  }

  onOverlayMenuClick(id: string) {
    if (id === 'withdraw') {
      this.requestWithdraw();
    }
  }

  interestAndUnInterest() {
    const { participation } = this.huddleView;
    this.publicService
      .interestAndUnInterest({
        huddleId: '' + this.huddleId,
        userId: '' + this.userId,
        interested: !participation.interested,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Huddle Public',
            detail: data.message,
          });
          this.getHuddlePublicly();
        },
        error: (err) => this.handleCatch(err),
      });
  }

  rsvp(rsvpType: string) {
    if (
      this.eventRsvpStartDateTime &&
      new Date() < this.eventRsvpStartDateTime
    ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Huddle RSVP',
        detail: `RSVP has not started yet. You can confirm your RSVP from ${format(this.eventRsvpStartDateTime, 'eeee, MMMM dd yyyy hh:mm a')}, onwards.`,
      });
      return;
    }

    if (this.eventRsvpEndDateTime && new Date() > this.eventRsvpEndDateTime) {
      this.messageService.add({
        severity: 'error',
        summary: 'Huddle RSVP',
        detail: `RSVP has ended at ${format(this.eventRsvpEndDateTime, 'eeee, MMMM dd yyyy hh:mm a')}.`,
      });
      return;
    }

    if (
      this.huddleView.participation?.role === 'HOST' &&
      (rsvpType === 'MAYBE' || rsvpType === 'YES')
    ) {
      this.isDialogVisible = true;
      return;
    }

    const {
      settings: { guestAllowed },
      paymentMode,
    } = this.huddleView;
    this.selectedRsvpType = rsvpType;
    if ((guestAllowed || paymentMode === 'PAID') && rsvpType !== 'NO') {
      this.visible = true;
      this.selectedGuest = rsvpType;
    } else {
      this.saveRsvp(rsvpType, null);
    }
  }

  saveRsvp(rsvpType: string, guests: any) {
    this.publicService
      .rsvp({
        huddleId: '' + this.huddleId,
        userId: '' + this.userId,
        rsvpType,
        ...(guests && { guests }),
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Huddle Public',
            detail: data.message,
          });
          this.rsvpType = rsvpType;
          this.visible = false;
          this.getHuddlePublicly();
        },
        error: (err) => this.handleCatch(err),
      });
  }

  requestWithdraw() {
    this.publicService
      .deleteRequestOrWaiting({
        huddleId: '' + this.huddleId,
        userId: '' + this.userId,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Huddle Public',
            detail: data.message,
          });
          this.getHuddlePublicly();
        },
        error: (err) => this.handleCatch(err),
      });
  }

  handleCatch(err: any) {
    this.messageService.add({
      severity: 'error',
      summary: 'Huddle Public',
      detail: err.error.message || err.message,
    });
  }

  addGuest() {
    const _guests: any = {};
    this.adult > 0 && (_guests['ADULT'] = this.adult);
    this.teens > 0 && (_guests['TEEN'] = this.teens);
    this.kids > 0 && (_guests['KID'] = this.kids);
    this.toddlers > 0 && (_guests['TODDLER'] = this.toddlers);
    this.seniors > 0 && (_guests['SENIOR'] = this.seniors);
    this.saveRsvp(
      this.selectedGuest,
      Object.keys(_guests).length > 0 ? _guests : null,
    );
  }

  getProfileImageById(id: number | undefined) {
    return environment.apiUrl + '/api/public/user/' + id + '/pic';
  }

  formatDate(startDate: Date, endDate: Date | null) {
    return formatEventDate(
      startDate.toISOString(),
      endDate?.toISOString() || '',
    );
  }

  calculateDuration(startDate: string, endDate: string | undefined) {
    return calculateDuration(startDate, endDate);
  }

  getAllowedAgeRanges() {
    return AgeRangesFields.filter((age) =>
      this.huddleView.settings.guestAllowedAges.includes(age.value),
    );
  }

  getPaymentMethodName(value: any) {
    return PaymentMethodName.getPaymentMethodName(value);
  }

  onRsvpChange(data: { error: boolean; message: string; rsvpType: string }) {
    if (!data.error) {
      this.rsvpType = data.rsvpType;
      this.getHuddlePublicly();
    }
  }

  closeDialog() {
    this.isDialogVisible = false;
  }
}
