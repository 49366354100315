<div #publicMain class="h-full overflow-auto" role="main" *ngIf="!isLoading">
  <div class="view-public sticky top-0 z-100 h-0 w-full" *ngIf="isSelfView">
    <div
      class="mask-bg absolute left-0 right-0 flex items-center justify-between px-4 py-4"
    >
      <span
        >This content on your profile is:
        <span class="material-icons inline-block align-bottom">public</span>
        Public
      </span>
      <button
        type="button"
        (click)="exit()"
        pButton
        class="mp-button mp-button-filled transition-al h-10 w-16 justify-center gap-2 rounded-md px-5 py-2 leading-6 md:w-auto"
      >
        Exit
      </button>
    </div>
  </div>
  <div class="surface-ground flex h-full flex-col dark:bg-[#1F242F]">
    <div>
      <div class="flex flex-wrap items-stretch justify-center">
        <div class="relative flex max-w-full shrink grow basis-0 flex-col">
          <p-card
            [styleClass]="
              'rounded-none public-profile-card ' + (!isSelfView ? 'pt-10' : '')
            "
          >
            <div
              class="bg-blur absolute h-[200px] w-full items-stretch overflow-hidden"
            >
              <img
                *ngIf="coverPhotoUrl"
                [src]="coverPhotoUrl"
                [ngStyle]="{
                  'top.px': userInfo.coverPhotoCoordinate.ycoordinate,
                  cursor: 'move',
                }"
                alt="cover photo"
                class="static top-0 min-h-full min-w-full shrink-0 object-cover object-top sm:absolute"
              />
              <img
                *ngIf="!coverPhotoUrl"
                src="../assets/images/user-cover-default.svg"
                alt="default cover photo"
                class="static top-0 min-h-full min-w-full shrink-0 object-cover object-top sm:absolute"
              />
            </div>

            <div
              [ngClass]="{
                '-mt-[2.5rem]': !isSelfView,
              }"
              class="relative m-auto flex h-[200px] w-full max-w-[860px] items-center items-stretch"
            >
              <div
                class="relative h-[200px] w-full bg-gradient-to-r pt-[200px]"
              >
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 mx-0 overflow-hidden px-0"
                >
                  <img
                    *ngIf="coverPhotoUrl"
                    [src]="coverPhotoUrl"
                    alt=""
                    [ngStyle]="{
                      'top.px': isMobile ? 0 : translateY,
                    }"
                    class="absolute top-0 min-h-full min-w-full shrink-0 object-cover object-top"
                  />
                  <img
                    *ngIf="!coverPhotoUrl"
                    src="../assets/images/user-cover-default.svg"
                    alt="default cover photo"
                    class="absolute bottom-0 min-h-full min-w-full shrink-0 object-cover object-top"
                  />
                </div>
                <div class="z-100">
                  <p-image
                    *ngIf="!profilePic"
                    [src]="'./assets/images/user-pic-default.svg'"
                    alt="Image"
                    styleClass="profile-pic absolute w-[200px] left-[50%] md:left-[10%] -translate-x-2/4"
                    width="200"
                  ></p-image>
                  <p-image
                    *ngIf="profilePic"
                    [src]="profilePic"
                    alt="Image"
                    styleClass="profile-pic absolute w-[200px] left-[50%] md:left-[10%] -translate-x-2/4"
                    width="200"
                  ></p-image>
                  <div
                    class="flex items-start justify-center pt-32 md:justify-between md:pt-2"
                  >
                    <div class="hidden sm:block">
                      <div class="ml-0 text-2xl font-bold md:ml-56">
                        {{ userDetails.displayName }}
                      </div>
                      <div class="ml-0 md:ml-56">
                        <span>&nbsp;{{ "@" + userDetails.username }}</span>
                        <ng-template
                          [ngIf]="userDetails.follower"
                          [ngIfElse]="followRequested"
                        >
                          <p-chip
                            *ngIf="userDetails.follower"
                            styleClass="public-chip ml-2 transition-all duration-200"
                            label="Follow you"
                          ></p-chip>
                        </ng-template>
                        <ng-template #followRequested>
                          <p-chip
                            *ngIf="userDetails.followerRequested"
                            styleClass="public-chip ml-2 transition-all duration-200"
                            label="Follow you requested"
                          ></p-chip>
                        </ng-template>
                      </div>
                      <div
                        class="shrink-1 grow-1 ml-0 mt-1 flex min-w-0 flex-col md:ml-56"
                      >
                        <div class="w-full">
                          <div
                            class="center flex flex-col items-start justify-center"
                          >
                            <span class="sub-title inline-flex items-center">
                              <span class="material-icons surface-400"
                                >location_on</span
                              >
                              <span class="surface-400"
                                >Location:
                                {{
                                  userDetails.location || "No Location"
                                }}</span
                              >
                            </span>
                            <span class="sub-title inline-flex items-center">
                              <span class="material-icons surface-400"
                                >link</span
                              >
                              <span class="surface-400"
                                >Connected since:
                                {{
                                  !isSelfView
                                    ? (userDetails.followingSince
                                      | date: "EEEE, MMMM d, y")
                                    : " Birth"
                                }}</span
                              >
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-12 flex flex-col gap-4 md:mt-0">
                      <div class="stretch flex grow items-center gap-4">
                        <button
                          pButton
                          type="button"
                          [disabled]="
                            userDetails.following ||
                            userDetails.followingRequested ||
                            isSelfView
                          "
                          (click)="followUser()"
                          [ngClass]="{ 'primary-bg': following }"
                          class="mp-button mp-button-outlined transition-al group h-10 w-full justify-center gap-2 rounded-md px-5 py-2 leading-6 md:w-auto"
                        >
                          <span
                            *ngIf="
                              !userDetails.loading &&
                              !userDetails.following &&
                              !userDetails.followingRequested
                            "
                            >Follow</span
                          >
                          <span
                            *ngIf="
                              !userDetails.loading && userDetails.following
                            "
                            >Following</span
                          >
                          <span
                            *ngIf="
                              !userDetails.loading &&
                              !userDetails.following &&
                              userDetails.followingRequested
                            "
                            >Follow Requested</span
                          >
                          <p-progressSpinner
                            *ngIf="userDetails.loading"
                            styleClass="horizontal-center w-5 h-5"
                            strokeWidth="4"
                          ></p-progressSpinner>
                        </button>
                        <button
                          pButton
                          type="button"
                          (click)="followingMenu.toggle($event)"
                          class="mp-button mp-button-outlined h-10 w-full justify-center rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
                        >
                          <span class="material-icons">more_horiz</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="ml-0 mt-16 flex flex-col items-center justify-between gap-4 md:mt-10 xl:ml-52 xl:mt-0 xl:flex-row xl:items-start xl:gap-0"
            >
              <div class="block flex flex-col text-center sm:hidden">
                <div class="z-[1] text-2xl font-bold">
                  {{ userDetails.displayName }}
                </div>
                <div class="ml-0 md:ml-56">
                  <span>{{ "@" + userDetails.username }}</span>
                  <ng-template
                    [ngIf]="userDetails.follower"
                    [ngIfElse]="followRequested"
                  >
                    <p-chip
                      *ngIf="userDetails.follower"
                      styleClass="public-chip ml-2 transition-all duration-200"
                      label="Follow you"
                    ></p-chip>
                  </ng-template>
                  <ng-template #followRequested>
                    <p-chip
                      *ngIf="userDetails.followerRequested"
                      styleClass="public-chip ml-2 transition-all duration-200"
                      label="Follow you requested"
                    ></p-chip>
                  </ng-template>
                </div>
                <div class="shrink-1 grow-1 flex min-w-0 flex-col px-4">
                  <div class="w-full">
                    <div
                      class="flex flex-col items-center justify-center gap-0 md:flex-row md:gap-4"
                    >
                      <span class="sub-title inline-flex items-center">
                        <span class="material-icons surface-400"
                          >location_on</span
                        >
                        <span class="surface-400"
                          >Location:
                          {{ userDetails.location || "No Location" }}</span
                        >
                      </span>
                      <span class="sub-title inline-flex items-center">
                        <span class="material-icons surface-400">link</span>
                        <span class="surface-400"
                          >Connected since:
                          {{
                            !isSelfView
                              ? (userDetails.followingSince
                                | date: "EEEE, MMMM d, y")
                              : " Birth"
                          }}</span
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="relative m-auto mt-20 flex w-full max-w-[890px] items-center items-stretch transition-all duration-300 md:mt-28"
            >
              <div class="shrink-1 grow-1 flex min-w-0 flex-col px-4">
                <div class="w-full">
                  <div class="flex items-center justify-center gap-4">
                    <p-avatarGroup>
                      <p-avatar
                        *ngFor="let mutual of mutualConnections"
                        size="large"
                        shape="circle"
                        [pTooltip]="mutual.displayName"
                        tooltipPosition="top"
                        tooltipEvent="focus"
                        tabindex="-1"
                      >
                        <img
                          *ngIf="mutual.profilePicUrl"
                          [src]="getProfileImage(mutual.id) | image | async"
                          alt=""
                        />
                        <img
                          *ngIf="!mutual.profilePicUrl"
                          src="../assets/images/user-pic-default.svg"
                          alt=""
                        />
                      </p-avatar>
                      <p-avatar
                        *ngIf="totalMutualConnections > 5"
                        label="+2"
                        shape="circle"
                        size="large"
                        [style]="{
                          'background-color': '#9c27b0',
                          color: '#ffffff',
                        }"
                      ></p-avatar>
                    </p-avatarGroup>
                    <span
                      class="font-semibold text-gray-600"
                      [ngClass]="{ 'mb-4': !totalMutualConnections }"
                      >{{ totalMutualConnections }} connections in common</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="relative m-auto flex w-full max-w-[890px] items-center items-stretch"
            >
              <div class="w-full border-t border-t-gray-300"></div>
            </div>

            <div class="sticky z-auto">
              <div
                class="relative m-auto flex w-full max-w-[890px] flex-col items-center justify-between gap-2 lg:flex-row lg:gap-0"
              >
                <p-tabMenu
                  [scrollable]="true"
                  [model]="tabs"
                  [activeItem]="activeTab"
                  styleClass="mp-menu"
                  (activeItemChange)="onActiveItemChange($event)"
                ></p-tabMenu>
              </div>
            </div>
          </p-card>
        </div>
      </div>
    </div>
    <div
      class="flex w-full justify-center"
      [ngClass]="{ hidden: activeTab?.label !== 'About' }"
    >
      <div class="h-full w-full max-w-md overflow-auto p-2">
        <p-card class="flex-1">
          <div class="p-0">
            <div class="pb-4 pt-0">
              <h2 class="h-level-2 font-bold">Personal Info</h2>
            </div>
            <div class="flex items-center gap-1 pb-3">
              <strong>First Name:</strong>
              <span>{{ userDetails.firstName }}</span>
            </div>
            <div class="flex items-center gap-1 pb-3">
              <strong>Last Name:</strong>
              <span>{{ userDetails.lastName }}</span>
            </div>
            <div class="flex items-center gap-1">
              <strong>Location:</strong>
              <span *ngIf="!userDetails.location">Not specified</span>
              <span>{{ userDetails.location }}</span>
            </div>
          </div>
        </p-card>
      </div>
    </div>
    <div
      class="mx-auto w-full md:w-[890px]"
      [ngClass]="{ hidden: activeTab?.label !== 'Posts' }"
    >
      <div
        #dashboardContainer
        *ngIf="!isLoading"
        class="surface-ground dashboard-container h-full overflow-auto px-2"
      >
        <div class="mx-auto max-w-[720px]">
          <div class="linear max mx-auto mb-24 transition-all duration-500">
            <div class="container mb-8 mt-2">
              <div
                class="search-results"
                infinite-scroll
                [infiniteScrollDistance]="1"
                [infiniteScrollUpDistance]="2"
                [infiniteScrollThrottle]="500"
                [infiniteScrollContainer]="publicMain"
                (scrolled)="onScrolledDown()"
                [fromRoot]="true"
              >
                <ng-container
                  *ngIf="userPosts && userPosts.length > 0; else noPosts"
                >
                  <div
                    *ngFor="let post of userPosts"
                    class="mb-2 last:mb-0"
                    [id]="post.id"
                  >
                    <app-post
                      [cardType]="post.type"
                      [post]="post"
                      [userInfo]="userInfo"
                      (postDelete)="onPostDelete($event)"
                      (sharePost)="sharePost($event)"
                    ></app-post>
                  </div>
                </ng-container>
                <ng-template #noPosts>
                  <div class="p-4 text-center">
                    <p>User has not posted anything yet.</p>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="overlay" *ngIf="isLoading">
    <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
  </div>

  <p-tieredMenu
    #followingMenu
    [model]="publicProfileOption"
    appendTo="body"
    [popup]="true"
    styleClass="p-0"
  >
    <ng-template pTemplate="p-menuitem" let-obj>
      <div
        [ngClass]="{
          'pointer-events-none opacity-40':
            obj.id === 'unfollow' && !userDetails.following,
        }"
        (click)="onMenuClick(obj.id)"
        class="flex cursor-pointer px-2 py-3"
      >
        <div class="font-normal">
          {{ obj.label }}
        </div>
      </div>
    </ng-template>
  </p-tieredMenu>

  <p-dialog
    header="Select User"
    [(visible)]="userPickerVisible"
    [modal]="true"
    [style]="{ width: '30vw' }"
    [breakpoints]="{ '960px': '80vw' }"
    [draggable]="false"
    [resizable]="false"
    [appendTo]="'body'"
    styleClass="p-lrb-clear"
  >
    <div #inviteUser class="relative mb-2 overflow-hidden transition-[height]">
      <app-user-picker-new
        [ref]="inviteUser"
        [showSave]="true"
        [buttonText]="'Invite'"
        [events]="userPickerEvent.asObservable()"
      ></app-user-picker-new>
    </div>
  </p-dialog>
</div>
