<div class="surface-ground h-full p-4">
  <div class="mx-auto max-w-[720px]">
    <div class="linear max mx-auto mb-24 transition-all duration-500">
      <div class="container mb-8">
        <p-card class="password mx-auto block md:mx-0 md:my-auto lg:w-[680px]">
          <div class="flex flex-col">
            <h3 class="mb-4 text-xl">Verify Your Email Address</h3>
            <p class="mb-2">
              To continue, please check your email and confirm your account.
            </p>
            <p>
              If you didn’t receive the email, check your spam or junk folder.
              You can also request a new verification email by clicking the
              button below.
            </p>
            <div
              class="mb-2 mt-4 flex flex-col justify-between gap-3 sm:flex-row sm:gap-0"
            >
              <p-button
                label="Go to Dashboard"
                [loading]="isDashboardLoading"
                styleClass="rounded-md mp-button mp-button-outlined px-5 py-2 shadow-sm leading-6 w-full sm:w-auto"
                (click)="redirect()"
              />
              <p-button
                label="Resend Verification Email"
                [loading]="isLoading"
                styleClass="rounded-md mp-button mp-button-filled px-5 py-2.5 shadow-sm leading-6 w-full sm:w-auto"
                (click)="sendEmailVerificationInstructions()"
              />
            </div>
          </div>
        </p-card>
      </div>
    </div>
  </div>
</div>
