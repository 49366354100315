import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { DEFAULT_HOME } from 'src/app/main/utilities';

@Component({
  selector: 'app-404',
  templateUrl: './notfound.component.html',
})
export class NotFoundComponent {
  DEFAULT_HOME = DEFAULT_HOME;

  constructor(private location: Location) {}

  goBack() {
    this.location.back();
  }
}
