<header
  class="border-b"
  [ngClass]="
    isDark
      ? 'surface-light mp-border-dark'
      : 'surface-lineargradient mp-border-light'
  "
>
  <div
    class="mobile-app-download-banner surface-overlay block border-b p-4 sm:hidden"
    *ngIf="!hideMobileBanner"
  >
    <div class="flex items-center">
      <button type="button" pButton class="mr-2" (click)="hideBanner()">
        <span class="material-icons">close</span>
      </button>
      <img
        [src]="
          !isDark
            ? '../assets/icons/icon-384x384.png'
            : '../assets/icons/icon-384x384.png'
        "
        [srcset]="
          !isDark
            ? '../assets/icons/icon-512x512.png'
            : '../assets/icons/icon-512x512.png'
        "
        alt="logo"
        class="mr-2 h-12 rounded-md"
      />
      <div class="flex flex-1 flex-col">
        <span class="h-level-3 flex-1">Mission Peak</span>
        <span class="font-xs flex-1">Free PWA app for iPhone and Android</span>
      </div>
      <a
        [href]="sdtkWebUrl + '/mobile'"
        target="_blank"
        class="mp-button mp-button-outlined cursor-pointer rounded-md px-5 py-2 transition"
      >
        Get App
      </a>
    </div>
  </div>
  <nav class="flex h-20 items-center p-4">
    <button class="block p-2 lg:hidden" (click)="openSideNav()">
      <fa-icon [icon]="faBars" size="xl"></fa-icon>
    </button>
    <div class="flex flex-1 self-stretch md:gap-x-6">
      <div class="flex flex-1 items-center">
        <h2 class="h-level-2 highlight-text font-semibold">
          {{ appBarTitle }}
        </h2>
      </div>
      <div class="flex items-center sm:gap-x-2 md:gap-x-4">
        <button id="plus" type="button" (click)="op.toggle($event)">
          <span
            class="material-icons inline-block align-middle !text-[2em]"
            [ngClass]="isDark ? 'surface-teal-300' : 'highlight-text'"
            >add</span
          >
        </button>
        <div *ngIf="userInfo?.preference?.betaUser">
          <button type="button">
            <span
              class="material-icons inline-block align-middle !text-[2em]"
              [ngClass]="isDark ? 'surface-teal-300' : 'highlight-text'"
              >search</span
            >
          </button>
        </div>
        <button
          *ngIf="userInfo?.preference?.betaUser"
          type="button"
          class="text-slate-500"
        >
          <span class=""></span>
          <span
            class="material-icons inline-block align-middle !text-[2em]"
            [ngClass]="isDark ? 'surface-teal-300' : 'highlight-text'"
            >chat</span
          >
        </button>
        <button
          type="button"
          class="relative"
          (click)="onClickOverlay(['notification'], '')"
        >
          <p-badge
            styleClass="h-4 min-w-[1rem] absolute left-[10px] -top-[6px] leading-[inherit]"
            severity="danger"
            *ngIf="notificationCount"
            [value]="notificationCount.toString()"
          ></p-badge>
          <span
            class="material-icons inline-block align-middle !text-[2em]"
            [ngClass]="isDark ? 'surface-teal-300' : 'highlight-text'"
            >notifications</span
          >
        </button>
        <div class="relative">
          <button
            class="flex items-center p-1.5"
            (click)="menuRef.toggle($event)"
            title="avatar"
          >
            <img
              class="h-8 w-8 rounded-full"
              [src]="picPhotoUrl"
              alt="default"
            />
          </button>
        </div>
      </div>
    </div>
  </nav>
</header>
<p-messages
  [(value)]="offlineMessage"
  *ngIf="!networkStatus"
  [enableService]="false"
  styleClass="offline-flag"
  [closable]="false"
></p-messages>
<p-messages
  [(value)]="browserViewMessage"
  [enableService]="false"
  styleClass="version"
  [closable]="true"
></p-messages>

<p-overlayPanel #op>
  <div class="flex grow flex-col gap-y-4 overflow-y-auto">
    <div class="flex shrink items-center">
      <h2 class="h-level-2">Create</h2>
    </div>
    <div class="flex flex-1 flex-col">
      <ul
        role="list"
        class="flex flex-1 flex-col transition ease-in-out [&>:not(:first-child)]:mt-2"
      >
        <li
          *ngFor="let option of overlayOptions['Dashboard']"
          id="plus_{{ option.id }}"
          (click)="onClickOverlay(option.routerLink, option.id)"
          [ngClass]="
            option.feature === 'DEV' && !userInfo.preference.betaUser
              ? 'hidden'
              : ''
          "
        >
          <a
            class="flex cursor-pointer items-center gap-3 rounded-md p-2 leading-6"
          >
            <fa-icon [icon]="option.icon" size="lg"></fa-icon>
            <div class="flex flex-col">
              <div class="">
                {{ option.text }}
              </div>
              <span class="sub-title">
                {{ option.description }}
              </span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</p-overlayPanel>

<app-user-tiered-menu (userMenuRef)="getMenuRef($event)"></app-user-tiered-menu>

<p-tieredMenu #theme [model]="avatarOptions" [popup]="true">
  <ng-template pTemplate="p-menuitem" let-obj>
    <div
      class="flex cursor-pointer px-2 py-3"
      *ngIf="obj.label !== 'Logout' && obj.label !== 'Feedback'"
    >
      <div class="font-normal">
        {{ obj.label }}
      </div>
    </div>
    <div
      class="flex cursor-pointer px-2 py-3"
      (click)="onClickFeedback()"
      *ngIf="obj.label === 'Feedback'"
    >
      <div class="font-normal">
        {{ obj.label }}
      </div>
    </div>
    <div
      class="flex cursor-pointer px-2 py-3"
      (click)="signOut()"
      *ngIf="obj.label === 'Logout'"
    >
      <div class="font-normal">
        {{ obj.label }}
      </div>
    </div>
  </ng-template>
</p-tieredMenu>

<p-dialog
  [modal]="true"
  [(visible)]="feedbackVisible"
  [breakpoints]="{ '960px': '90vw' }"
  [style]="{ width: '30vw' }"
  [draggable]="false"
  [resizable]="false"
  appendTo="body"
  styleClass="post-dialog"
>
  <ng-template pTemplate="header">
    <h1 class="text-xl font-bold">Feedback</h1>
  </ng-template>
  <div class="grid grid-cols-1 gap-x-6 gap-y-8 p-4 sm:grid-cols-6">
    <div class="col-span-6 md:col-span-6">
      <div>
        If you need help, want to provide feedback, or would like to request a
        new feature, please reach out to support&commat;siddhatek.com. Thank
        you.
      </div>
    </div>
    <div class="col-span-6 grid grid-cols-1 gap-x-6 gap-y-2 md:col-span-6">
      <p-button
        type="button"
        label="Close"
        (click)="feedbackVisible = false"
        class="text-right"
        styleClass="rounded-md px-5 py-2 font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 leading-6"
      >
      </p-button>
    </div>
  </div>
</p-dialog>

<app-post-dialog
  [postDialogEvent]="postDialogEvent.asObservable()"
></app-post-dialog>

<p-confirmDialog #cd styleClass="md:w-5/12 w-10/12" appendTo="body">
  <ng-template pTemplate="header">
    <h3 class="h-level-3">Create a new Carpool</h3>
  </ng-template>
  <ng-template pTemplate="message">
    <p class="mt-2">
      Would you like to set up a carpool independently? It's recommended to
      start by creating a Huddle for your carpool group, and then create or join
      a carpool from within that Huddle.
    </p>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="mt-2 flex flex-col justify-start gap-4 xl:flex-row">
      <div class="order-2 flex-1 text-left xl:order-none">
        <button
          type="button"
          pButton
          (click)="cd.reject()"
          class="mp-button mp-button-outlined w-full justify-center rounded-md border px-5 py-2 leading-6 transition-all xl:w-auto"
        >
          Cancel
        </button>
      </div>
      <button
        pButton
        (click)="onConfirm('/huddle/carpool/create', true)"
        class="mp-button mp-button-filled justify-center truncate rounded-md px-5 py-2 leading-6 shadow-sm"
      >
        Create Carpool
      </button>
      <button
        pButton
        (click)="onConfirm('/huddle/create', false)"
        class="mp-button mp-button-filled justify-center truncate rounded-md px-5 py-2 leading-6 shadow-sm"
      >
        Create Huddle for Carpools
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
