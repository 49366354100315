import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import {
  IconDefinition,
  faAngleLeft,
  faBars,
  faBell,
  faCalendar,
  faMessage,
  faMoon,
  faPaperPlane,
  faPlus,
  faSearch,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { Overlay } from 'primeng/overlay';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TieredMenu } from 'primeng/tieredmenu';
import {
  Observable,
  Subject,
  Subscription,
  fromEvent,
  map,
  merge,
  of,
  tap,
} from 'rxjs';
import {
  avatarOptions,
  avtarOptions,
  overlayOptions,
  themeOptions,
} from 'src/app/main/utilities/overlayHelper';
import { UserService } from '../../../main/services/user.service';
import {
  App,
  DialogEvent,
  Location,
  NotificationSummary,
  User,
  UserDetails,
} from '../../../main/types/main.types';
import { ImagePipe } from '../../pipes/image.pipe';
import { CommunityService } from '../../services/community.service';
import { ConnectionsService } from '../../services/connections.service';
import { InspirationService } from '../../services/inspiration.service';
import { PostService } from '../../services/post.services';
import { ThemeService } from '../../services/theme.service';
import { UserActionType } from '../../store/actions/user.action';
import { AppState } from '../../store/model/state.model';
import { THEME } from '../../store/reducers/app.reducer';
import { environment } from './../../../../environments/environment';

enum Menu {
  MAIN = 'M',
  THEME = 'T',
}

enum Dialog {
  POST = 'POST',
  USER = 'USER',
  LOCATION = 'LOCATION',
}

@Component({
  selector: 'app-appbar',
  templateUrl: './appbar.component.html',
})
export class AppbarComponent implements OnInit, OnDestroy {
  faBars = faBars;
  title: string;

  faPlus: IconDefinition = faPlus;

  faSearch: IconDefinition = faSearch;

  faBell: IconDefinition = faBell;

  faUsers: IconDefinition = faUsers;

  faCalender: IconDefinition = faCalendar;

  faMessage: IconDefinition = faMessage;

  faPaperPlane: IconDefinition = faPaperPlane;

  faMoon: IconDefinition = faMoon;

  faAngleLeft: IconDefinition = faAngleLeft;

  visible = false;

  overlayOptions: any = overlayOptions;

  avatarOptions: any = avatarOptions;

  themeOptions: any = themeOptions;

  avtarOptions = avtarOptions;

  sideNavStatus = false;

  showSubMenu = false;

  showMenu = true;

  themes: any = avatarOptions.find((a) => a.label === 'Theme');

  selectedTheme: string;

  notificationCount: number;

  followings: User[] = [];

  selectedUser: User[] = [];

  selectedAddress: Location[] = [];

  selectedAddressLength = 0;

  selectedUserLength = 0;

  postTitle = 'Create Post';

  taggedUser: any = {};

  taggedAddress: any = {};

  mentionConfig: any;

  atValues: any;

  quill: any;

  profilePic: string | undefined = undefined;

  notification$: Observable<NotificationSummary>;

  userPickerEvent: Subject<any> = new Subject<any>();

  dialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();

  isLoading = false;

  feedbackVisible = false;

  appState$: Observable<App>;

  appBarTitle: string;

  userDetail$: Observable<UserDetails>;

  userInfo: UserDetails;

  showCookie = true;

  picPhotoUrl = '../assets/images/user-pic-default.svg';

  hideMobileBanner = false;

  networkStatus = false;

  isDark = false;

  offlineMessage: Message[];

  browserViewMessage: Message[];

  sdtkWebUrl = environment.sdtkWebUrl;

  isBrowser = true;

  menuRef: TieredMenu;

  postDialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();

  @Input() isSidebarOpen: boolean;

  @ViewChild('tieredMenu', { static: false }) customMenu: Overlay;

  @ViewChild('mainMenu', { static: false }) mainMenu: ElementRef;

  @ViewChild('overlay', { static: false }) overlay: ElementRef;

  @ViewChild('themeMenu', { static: false }) themeMenu: ElementRef;

  @ViewChild('op', { static: false }) plusMenu: OverlayPanel;

  @ViewChild('cd', { static: false }) confirmDialog: ConfirmDialog;

  networkStatus$: Subscription = Subscription.EMPTY;

  @Output() sideNavOpenEvent = new EventEmitter<{ isSideNavOpen: boolean }>();

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private store: Store<AppState>,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private connectionService: ConnectionsService,
    private postService: PostService,
    private messageService: MessageService,
    private themeService: ThemeService,
    private imagePipe: ImagePipe,
    private inspirationService: InspirationService,
    @Inject(PLATFORM_ID) platformId: object,
    private communityService: CommunityService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    window.onresize = () => {
      if (window.innerWidth <= 768) {
        this.visible = false;
      }
    };

    if (window.matchMedia('(display-mode: standalone)').matches) {
      localStorage.removeItem('version_compatibility');
      this.hideBanner();
    }
  }

  ngOnInit(): void {
    const isBanner = localStorage.getItem('banner');
    this.loadQuillModule();
    this.appState$ = this.store
      .select((store) => store.app)
      .pipe(
        tap((appState: App) => {
          this.appBarTitle = appState.appbarTitle;
        }),
      );
    this.appState$.subscribe();

    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.userInfo = user;
          const theme =
            user.theme === 'LIGHT' ? 'lara-light-teal' : 'lara-dark-teal';
          this.changeUserTheme(user.theme, theme);
          this.selectedTheme = user.theme;
          if (user.profilePicUrl)
            this.imagePipe
              .transform(`${environment.apiUrl}/api/user/${user.id}/pic`)
              .subscribe((image: string | null) => {
                this.picPhotoUrl = image as string;
                console.log(this.picPhotoUrl);
              });
        }),
      );
    this.userDetail$.subscribe();

    this.notification$ = this.store
      .select((store) => store.notifications)
      .pipe(
        tap(({ countNewTotal }) => {
          this.notificationCount = countNewTotal;
        }),
      );
    this.notification$.subscribe();
    this.checkNetworkStatus();
    isBanner && (this.hideMobileBanner = true);

    const versionCompatibility = localStorage.getItem('version_compatibility');
    if (!versionCompatibility && !this.hasGoogleChrome) {
      this.browserViewMessage = [
        {
          severity: 'info',
          detail:
            'Note: MissionPeak App is best viewed in the latest version of Chrome for optimal performance and experience.',
        },
      ];
      localStorage.setItem('version_compatibility', 'true');
    }
  }

  async loadQuillModule() {
    if (!this.isBrowser) {
      return;
    }
    // const module = await import('quill'); // Dynamically import Quill
    // this.editor = new module.default('#editor', {
    //   theme: 'snow',
    // });
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }

  openSideNav() {
    this.sideNavStatus = !this.isSidebarOpen;
    this.sideNavOpenEvent.emit({ isSideNavOpen: this.sideNavStatus });
  }

  signOut() {
    this.authService.logout({
      logoutParams: {
        returnTo: environment.appUrl + '/start',
      },
    });
  }

  onMenuClick(routerLink: [] | undefined, label: string) {
    if (label === 'Logout') {
      this.signOut();
    } else if (label === 'Theme') {
      this.showSubMenu = !this.showSubMenu;
      this.setOverlayHeight(this.showSubMenu ? Menu.THEME : Menu.MAIN);
    } else if (label === 'Post') {
      this.postTitle = 'Create Post';
      this.setOverlayHeight(Dialog.POST);
    } else if (label === 'Feedback') {
      this.feedbackVisible = true;
      this.customMenu.hide();
    } else {
      routerLink && this.router.navigate([routerLink]);
      this.customMenu.hide();
    }
  }

  onOverlayShow() {
    this.showSubMenu = false;
    this.setOverlayHeight(Menu.MAIN);
  }

  setOverlayHeight(el: string) {
    let parentHeight = 0;
    switch (el) {
      case Menu.MAIN: {
        const mainMenuRect =
          this.mainMenu.nativeElement.getBoundingClientRect();
        parentHeight =
          mainMenuRect.height + 69 <= 500
            ? mainMenuRect.height + 90
            : mainMenuRect.height;
        break;
      }
      case Menu.THEME: {
        const themeRect = this.themeMenu.nativeElement.getBoundingClientRect();
        parentHeight = themeRect.height;
        break;
      }
    }

    if ([Menu.MAIN, Menu.THEME].includes(el as Menu))
      this.overlay.nativeElement.style.height = parentHeight + 'px';
  }

  updateUserTheme(themeValue: string, href: string) {
    this.userService.updateUserTheme(themeValue).subscribe({
      next: (data: any) => {
        console.log('User theme updated.');
        const userInfoClone: UserDetails = { ...this.userInfo };
        userInfoClone.theme = themeValue;
        this.changeUserTheme(themeValue, href);
        this.store.dispatch({
          type: UserActionType.USER_DETAIL,
          payload: userInfoClone,
        });
      },
      error: (e) => {
        console.error('Request to update user theme errored out.');
        console.error(e);
        // show error toast message in the bottom right corner
      },
      complete: () => console.info('Request to update user theme completed.'),
    });
  }

  changeUserTheme(themeValue: string, href: string) {
    themeValue = themeValue?.toLowerCase();
    let media: MediaQueryList;
    let isDark = false;
    if (themeValue !== 'system') {
      this.themeService.switchTheme(href);
      isDark = themeValue.toLowerCase() === 'dark';
    } else {
      media = window.matchMedia('(prefers-color-scheme: light)');
      media.matches
        ? this.themeService.switchTheme('lara-light-teal')
        : this.themeService.switchTheme('lara-dark-teal');
      isDark = !media.matches;
    }
    this.selectedTheme = themeValue?.toUpperCase();
    this.isDark = isDark;
    this.store.dispatch({
      type: THEME,
      payload: isDark,
    });
  }

  onClickOverlay(routerLink: string[] | undefined, id: string) {
    if (id === 'carpool') {
      this.confirmationService.confirm({
        accept: () => {},
        reject: () => {},
      });
    } else if (id === 'post') {
      this.postDialogEvent.next({
        visible: true,
        dialogType: 'POST',
      });
    } else if (id === 'inspiration') {
      this.createDraft();
    } else if (id === 'community') {
      this.initCommunity();
    } else if (routerLink) {
      this.router.navigate(routerLink);
    }
    this.plusMenu.hide();
  }

  getFollowings(page: number, sort = '', name = '') {
    this.connectionService.getFollowings(page, sort, name).subscribe({
      next: (data: any) => {
        console.log('Request to get followings succeeded');
        this.followings = [...this.followings, ...data.data];
      },
      error: (err) => {
        console.error('Request to get get followings errored out. ' + err);
      },
    });
  }

  createPost() {
    let content = '';
    const p = this.quill.root.childNodes[0];
    this.taggedAddress = {};
    this.taggedUser = {};
    this.isLoading = true;
    for (const node of p.childNodes) {
      if (node.nodeType === Node.ELEMENT_NODE) {
        node.dataset.id !== 'sep' && (content += `{{${node.dataset.key}}}`);
        node.dataset.id === 'sep' && (content += `${node.dataset.value}`);
        node.dataset.keys === 'users' &&
          (this.taggedUser[node.dataset.value] = node.dataset.id);
        node.dataset.keys === 'address' &&
          (this.taggedAddress[node.dataset.id] = JSON.parse(node.dataset.json));
      } else {
        content += `${node.nodeValue}`;
      }
    }
    this.postService
      .createPost({
        content,
        taggedUsers: this.taggedUser,
        taggedLocations: this.taggedAddress || {},
        taggedHashes: {},
      })
      .subscribe({
        next: (data: any) => {
          this.postDialogEvent.next({
            visible: false,
            dialogType: 'POST',
          });
          this.userPickerEvent.next([]);
          this.isLoading = false;
          this.messageService.add({
            key: 'post',
            data: data.id,
            severity: 'success',
            summary: 'Post',
            detail: 'Post created successfully',
          });
        },
        error: (err) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Post',
            detail: err.message,
          });
        },
      });
  }

  getProfilePic(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  onClickFeedback() {
    console.log('click');
    this.feedbackVisible = true;
  }

  reloadCurrentRoute(id: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['timeline', 'view', id]);
    });
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline'),
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.networkStatus = status;
        this.offlineMessage = [
          { severity: 'warn', detail: 'You are currently offline.' },
        ];
      });
  }

  hideBanner() {
    localStorage.setItem('banner', 'true');
    this.hideMobileBanner = true;
  }

  onConfirm(link?: string, isCarpool?: boolean) {
    if (!isCarpool && link) {
      this.router.navigate([link], {
        queryParams: { activity: 'general', features: 'carpooling' },
      });
    } else if (isCarpool) {
      this.router.navigate([link]);
    }
    this.confirmationService.close();
  }

  get hasGoogleChrome() {
    const winNav = (window as any).navigator;
    return winNav.userAgentData.brands[2].brand === 'Google Chrome';
  }

  createDraft() {
    this.inspirationService.createInspiration().subscribe({
      next: (data: any) => {
        this.router.navigate(['/inspiration', 'edit'], {
          queryParams: { draftId: data.id },
        });
      },
      error: (err) => {
        const error = err?.error;
        const errorMessage = error?.message || 'Something went wrong.';
        this.messageService.add({
          severity: 'error',
          summary: 'Create Inspiration',
          detail: errorMessage,
        });
      },
    });
  }

  getMenuRef(ref: any) {
    this.menuRef = ref;
  }

  initCommunity() {
    this.communityService.initCommunity().subscribe({
      next: (data: any) => {
        if (data) {
          this.router.navigate(['/community', 'create']);
        }
      },
      error: (err) => {
        const errorMessage =
          err.error?.message ||
          'There was an issue checking your community count.';
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: errorMessage,
        });
      },
    });
  }
}
