<main class="main">
  <section class="surface-overlay">
    <div class="mx-auto max-w-[1120px]">
      <app-shared-inspiration-view
        [inspirationId]="inspirationId"
        styleClass="max-w-[1120px] max-auto"
      ></app-shared-inspiration-view>
    </div>
  </section>
</main>
