import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
})
export class SignInComponent {
  constructor(public authService: AuthService) {
    console.log(authService);
    this.signIn();
  }

  signIn() {
    this.authService.loginWithRedirect();
  }
}
