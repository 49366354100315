import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';

import { environment } from './../../../environments/environment';
import { NotificationSummary } from '../types/main.types';

class RetriableError extends Error {}
class FatalError extends Error {}

@Injectable({
  providedIn: 'root',
})
export class SseService {
  private apiURL = environment.apiUrl;
  private notificationTopic = '/api/sse/subscribe';

  constructor(private authService: AuthService) {}

  createFetchEventSource(): Observable<NotificationSummary> {
    return new Observable((observer) => {
      this.authService.getAccessTokenSilently().subscribe((token) => {
        /*
         * https://github.com/Azure/fetch-event-source
         */
        fetchEventSource(this.apiURL + this.notificationTopic, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
          async onopen(response) {
            if (
              response.ok &&
              response.headers.get('content-type') === EventStreamContentType
            ) {
              return; // everything's good
            } else if (
              response.status >= 400 &&
              response.status < 500 &&
              response.status !== 429
            ) {
              // client-side errors are usually non-retriable:
              throw new FatalError();
            } else {
              throw new RetriableError();
            }
          },
          onmessage(msg) {
            // if the server emits an error message, throw an exception
            // so it gets handled by the onerror callback below:
            if (msg.event === 'FatalError') {
              throw new FatalError(msg.data);
            } else {
              const summary: NotificationSummary = JSON.parse(msg.data);
              observer.next(summary);
            }
          },
          onclose() {
            // if the server closes the connection unexpectedly, retry:
            throw new RetriableError();
          },
          onerror(err) {
            if (err instanceof FatalError) {
              throw err; // rethrow to stop the operation
            } else {
              // do nothing to automatically retry. You can also
              // return a specific retry interval here.
              return 60 * 1000; // retry in 1 minute
            }
          },
        });
      });
    });
  }
}
