import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { publicMenuOptions } from 'src/app/main/utilities/overlayHelper';
import { ThemeService } from '../../main/services/theme.service';
import { Overlay } from 'primeng/overlay';
import { environment } from '../../../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { UserDetails } from 'src/app/main/types/main.types';
import { Observable, tap } from 'rxjs';
import { AppState } from 'src/app/main/store/model/state.model';
import { Store } from '@ngrx/store';
import { ImagePipe } from 'src/app/main/pipes/image.pipe';
import { TieredMenu } from 'primeng/tieredmenu';
import { DOCUMENT } from '@angular/common';
import { PlatformService } from 'src/app/main/services/platform.service';
import { DEFAULT_HOME } from 'src/app/main/utilities';

@Component({
  selector: 'app-appbar-public',
  templateUrl: './appbar-public.component.html',
})
export class AppbarPublicComponent implements OnInit {
  sidebarVisible = false;

  isDark = false;

  appliedTheme = 'system';

  selectedTheme: any;

  itemFor = '';

  showProducts = false;

  publicMenuOptions = publicMenuOptions;

  media: MediaQueryList;

  authenticated = false;

  sdtkWebUrl = environment.sdtkWebUrl;

  userDetail$: Observable<UserDetails>;

  userInfo: UserDetails;

  picPhotoUrl = '../assets/images/user-pic-default.svg';

  menuRef: TieredMenu;

  showMask = false;

  DEFAULT_HOME = DEFAULT_HOME;

  @ViewChild('tieredMenu', { static: false }) tieredMenu: Overlay | undefined;

  // @ViewChild('products', { static: false }) products: Overlay | undefined;

  constructor(
    readonly themeService: ThemeService,
    readonly route: Router,
    public authService: AuthService,
    private store: Store<AppState>,
    private imagePipe: ImagePipe,
    private platformService: PlatformService,
    @Inject(DOCUMENT) private doc: Document,
  ) {
    if (this.platformService.getIsBrowser()) {
      const _theme = localStorage.getItem('theme');
      this.media = window.matchMedia('(prefers-color-scheme: light)');
      this.selectedTheme = this.publicMenuOptions.find(
        (m: any) => m.action === (_theme || 'system'),
      );
      console.log(this.selectedTheme);
      this.toggleTheme(_theme || 'system', this.media);
      this.media.addEventListener('change', (e: MediaQueryListEvent) => {
        this.toggleTheme('system', e);
      });
    }
  }

  ngOnInit(): void {
    if (this.platformService.getIsBrowser()) {
      this.authService.isAuthenticated$.subscribe((authenticated: boolean) => {
        if (authenticated) {
          this.authenticated = authenticated;
          localStorage.removeItem('isDark');
        }
        if (!authenticated) {
          const _theme = localStorage.getItem('theme');
          this.media = window.matchMedia('(prefers-color-scheme: light)');
          this.selectedTheme = this.publicMenuOptions.find(
            (m: any) => m.action === (_theme || 'system'),
          );
          this.toggleTheme(_theme || 'system', this.media);
          this.media.addEventListener('change', (e: MediaQueryListEvent) => {
            this.toggleTheme('system', e);
          });
        }
      });
    }

    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.userInfo = user;
          if (user.theme)
            this.selectedTheme = this.publicMenuOptions.find(
              (m: any) => m.action === user.theme.toLowerCase(),
            );
          if (user.profilePicUrl)
            this.imagePipe
              .transform(`${environment.apiUrl}/api/user/${user.id}/pic`)
              .subscribe((image: string | null) => {
                this.picPhotoUrl = image as string;
              });
        }),
      );
    this.userDetail$.subscribe();
  }

  onToggleOverlayMenu(e: any, item: string) {
    this.itemFor = item;
    this.tieredMenu?.show(e);
  }

  onTieredMenuClick(action: string) {
    switch (action) {
      case 'dark':
        this.toggleTheme(action);
        break;
      case 'light':
        this.toggleTheme(action);
        break;
      case 'system':
        this.toggleTheme(action, this.media);
    }
    this.appliedTheme = action;
    this.selectedTheme = this.publicMenuOptions.find(
      (item: any) => item.action === action,
    );
    this.tieredMenu?.hide();
    localStorage.setItem('theme', action);
  }

  toggleTheme(theme: string, e?: MediaQueryList | MediaQueryListEvent) {
    if (theme.toLowerCase() === 'system') {
      if (e?.matches) {
        this.isDark = false;
        this.themeService.switchTheme('lara-light-teal');
      } else if (!e?.matches) {
        this.isDark = true;
        this.themeService.switchTheme('lara-dark-teal');
      }
    } else if (theme === 'light') {
      this.isDark = false;
      this.themeService.switchTheme('lara-light-teal');
    } else {
      this.isDark = true;
      this.themeService.switchTheme('lara-dark-teal');
    }
    this.appliedTheme = theme;
    localStorage.setItem('isDark', '' + this.isDark);
    this.themeService.setIsDarkTheme(this.isDark);
  }

  redirectTo(path: string[]) {
    this.route.navigate(path);
    this.sidebarVisible = false;
    this.showMask = false;
  }

  signUp() {
    this.authService.loginWithRedirect({
      appState: { target: DEFAULT_HOME },
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  }

  signIn() {
    this.authService.loginWithRedirect({
      appState: { target: DEFAULT_HOME },
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    });
  }

  getMenuRef(ref: any) {
    this.menuRef = ref;
  }

  onSidebarShowAndHide(show: boolean) {
    const sidebarMask = this.doc.querySelector('.p-sidebar-mask');
    if (sidebarMask) {
      show && sidebarMask.classList.add('hidden');
      !show && sidebarMask.classList.remove('hidden');
    }
    this.showMask = show;
  }

  onMaskClick() {
    this.sidebarVisible = false;
    this.showMask = false;
  }
}
